/* review  */

.review .question_bank_table_top {
  display: flex;
  height: 89vh;
}

.review .question_bank_table_top .question_bank {
  flex: 5;
  margin-right: 30px;
  border-radius: 12px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.review .question_bank_table_top .question_bank .table_content_report .top_content_table .filter {
  display: flex;
  margin-left: 40em;
}

.review .question_bank_table_top .data_tags {
  font-family: 'IBM Plex Sans', sans-serif;
}

.review .question_bank_table_top .data_tags .user_data_id {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 2em;
}

.review .question_bank_table_top .data_tags .user_data_id .some_text_tags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.review .question_bank_table_top .data_tags .some_text_tags .user_topics {
  display: flex;
  align-items: center;
  background-color: #e9f8ff;
  height: 3vh;
  border-radius: 50px;
  font-size: 10px;
  padding: 2px;
}

.review .question_bank_table_top .data_tags .user_data_id .user-subjects {
  margin-left: 12px;
}

.review .question_bank_table_top .data_tags .user_data_id .sub-lines {
  margin-left: 5px;
}

.review .question_bank_table_top .data_tags .user_genral_tags {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.review .question_bank_table_top .data_tags .user_author {
  text-align: center;
}

.review .question_bank_table_top .data_tags .user_genral_tags .tag_num {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #f4f5f6;
  padding: 5px;
  height: 20px;
  width: 90px;
}

.review .question_bank_table_top .data-tag .some_tags {
  padding: 10px;
  padding-left: 1em;
}

/**** Dropdown **/
.user_status {
  width: 120px;

  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 10px;
  height: 4vh;
}

.dropdown .dropdown_input {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  background: #fff9e8;
  color: #ffcf00;
  border: 0.3px solid #ffcf00;
  font-size: x-small;
}

.dropdown .dropdown_input_approved {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  background: #e9fcf1;
  color: #26ab5b;
  border: 0.3px solid #26ab5b;
  font-size: x-small;
}

.dropdown .dropdown_input_rejected {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  background: #fff2f1;
  color: #ff5757;
  border: 0.3px solid #ff5757;
  font-size: x-small;
}

.dropdown .dropdown_input span {
  margin-left: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.dropdown .dropdown_input_approved span {
  margin-left: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.dropdown .dropdown_input_rejected span {
  margin-left: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.dropdown_options {
  font-size: 10px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown .dropdown_options .dropdown_option {
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.dropdown .dropdown_option:last-child {
  border-bottom: none;
}

.dropdown.open .dropdown_options {
  display: block;
}

.todo_comment_button {
  padding: 20px;
  padding-left: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todo_comment_button .comm_bt {
  border-radius: 50px;
  width: 48px;
  height: 48px;
  border: 1px solid #ffffff;
  box-shadow: 0px 8px 40px 0px #7090b033;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.todo_comment_button .comm_bt:focus {
  border: 1px solid #00abfb;
}
.filters_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

/* _______________________ question-bank ____________________________ */
.question_bank_table_top {
  padding: 30px 30px;
}

.question_bank {
  width: 100%;
}

.table_content_report {
  width: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: 2%;
  height: 100%;
}

.table_content_report .top_content_table {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table_title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 30%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
}

.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 13px;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.underline_active {
  border-bottom: 5px solid #00abfb;
}

.created_date,
.lod {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
}

/***** COMMENTS SECTION FROM HERE **********/
.question_bank_table_top .comments_bar {
  flex: 1;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  gap: 2em;
  margin-top: 1.5em;
}

.question_bank_table_top .comments_bar .add_user_comment {
  height: 60vh;
  width: 220px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 8px 24px 0px #7090b026;
}

.question_bank_table_top .comments_bar .headline {
  display: flex;
  align-items: center;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: small;
  padding: 10px;
  gap: 5px;
}

.question_bank_table_top .comments_bar .user_comment_activity {
  background-color: #fff;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  height: 80vh;
  width: 220px;
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: 0px 8px 24px 0px #7090b026;
}

.question_bank_table_top .comments_bar .user_comment_activity .comments_area {
  display: flex;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 400;
  height: 60vh;
  overflow-y: scroll;
  padding: 5px;
}

.question_bank_table_top .comments_bar .user_comment_activity .comments_area::-webkit-scrollbar {
  width: 0px;
}

.question_bank_table_top .comments_bar .user_comment_activity .comments_area .comments_table .comments_rows {
  display: flex;
  gap: 10px;
}

.question_bank_table_top .comments_bar .user_comment_activity .comments_area .comments_table .data_rows {
  display: flex;
  margin-left: 15%;
}

.question_bank_table_top .comments_bar .user_comment_activity .comments_area .comments_table .data_rows .user_name {
  color: #8e9eab;
}

.question_bank_table_top .comments_bar .user_comment_activity .comments_area .comments_table .action_head {
  font-size: 13px;
  font-weight: 400;
}

.question_bank_table_top .comments_bar .user_comment_activity .comments_area .comments_table .action_time {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 10px;
  color: lightgray;
  border-bottom: 1px solid #e2ecf0;
  padding: 5px;
}

/***************************************** COMMENT BOX USING STATES **************************/

.question_bank_table_top .add_user_comment {
  height: 50vh;
}

.comments_bar.dynamic_comment_area {
  height: 50vh;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dynamic_comment_area .comment_box {
  background-color: #fff;
  height: 20vh;
  width: 200px;
  margin-top: 5px;
  margin-left: 4px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  line-height: 30px;
  border: 1px solid lightgray;
  font-family: 'IBM Plex Sans', sans-serif;

  color: #8e9eab;
}

.dynamic_comment_area .comment_box .enter {
  width: 190px;
  height: 10vh;
  max-width: fit-content;
  overflow-wrap: break-word;
  word-break: break-all;
}

.dynamic_comment_area .comment_box .enter:focus {
  outline: none;
}

.dynamic_comment_area .comment_box .type_comment_box {
  display: flex;
  align-items: center;
  width: 200px;
  margin-top: 4em;
  border-radius: 16px;
  gap: 3px;
  border: 1px solid #8e9eab;
}

.dynamic_comment_area .comment_box .type_comment_box input {
  width: 75px;
  border: none;
  height: 3vh;
}

.dynamic_comment_area .comment_box .type_comment_box input:focus {
  outline: none;
}

.dynamic_comment_area .comment_box .type_comment_box .send_bt {
  width: 30px;
  background: #ffffff;
  box-shadow: 0px 8px 40px rgba(112, 144, 176, 0.2);
  border-radius: 100px;
  height: 4vh;
  border: none;
  margin-left: 5em;
  cursor: pointer;
}

.dynamic_comment_area .view_other_comments {
  height: 25vh;
  padding: 5px;
}

.dynamic_comment_area .view_other_comments::-webkit-scrollbar {
  width: 0px;
}

.dynamic_comment_area .view_other_comments .task_id {
  width: 100px;
  background: #fff9e8;
  border-radius: 8px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dynamic_comment_area .view_other_comments .dynamic_comment_table {
  font-size: 13px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
}

.dynamic_comment_area .view_other_comments .dynamic_comment_table .user_name {
  color: #8e9eab;
}

.dynamic_comment_area .view_other_comments .dynamic_comment_table .dynamic_comment_row {
  font-size: 12px;
  gap: 10px;
  display: flex;
}

.dynamic_comment_area .view_other_comments .data_rows {
  display: flex;
  margin-left: 15%;
}

.dynamic_comment_area .view_other_comments .action_time {
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 10px;
  color: lightgray;
  border-bottom: 1px solid #e2ecf0;
  padding: 5px;
}

/**** CHANGING STATE OF COMMENTS AREA ON CLICKINNG OF BUTTON ***/
.comments_screen .dynamic_comment_area {
  display: none;
  transition: opacity 0.5s ease;
}

.comments_screen .dynamic_comment_area.visible {
  display: block;
  opacity: 1;
}

/** MEDIA SCREEN RESPONSIVE**/
@media screen and (max-width: 1400px) and (max-height: 700px) and (-webkit-min-device-pixel-ratio: 1) {
  .table_title {
    background-color: khaki;
  }
  .table_title span {
    font-size: 12px;
  }
}
