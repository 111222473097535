.import_questions {
  width: 95%;
  height: 464px;
  top: 129px;
  left: 196.86px;
  gap: 0px;
  border-radius: 18px;
  box-shadow: 0px 8px 24px 0px #7090b026;
  margin-left: 27px;
}

.inline_fields {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.input_field {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input_fieldlabel {
  margin-bottom: 4px;
}

.error_text {
  color: red;
  font-size: 0.9em;
  margin-left: 10px;
}

/* For the specific styling of the Name field */
.input_field.name_group {
  width: 376px;
  height: 80px;
  border-radius: 12px;
  position: absolute;
  top: 175px;
  left: 45px;
}

/* For the styling of the input inside the Name field */
.input_field.name_group input {
  height: calc(100% - 60px);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* For the specific styling of the Question Bank field */
.input_field.question_bank_group {
  width: 376px;
  height: 80px;
  border-radius: 12px;
  position: absolute;
  top: 175px;
  left: 450px;
}

/* For the styling of the select dropdown inside the Question Bank field */
.input_field.question_bank_group select {
  height: calc(100% - 40px);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

/* For the specific styling of the Passage Document field */
.input_field.passage_document_group {
  width: 416px;
  height: 110px;
  border-radius: 12px;
  position: absolute;
  top: 260px;
  left: 45px;
  gap: 8px;
}

/* For styling the file input container inside Passage Document */
.input_field.passage_document_group .file_input_container,
.input_field.question_document_group .file_input_container,
.input_field.solution_document_group .file_input_container,
.input_field.mapping_sheet_group .file_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* For the button inside Passage Document */
.input_field.passage_document_group .file_input_container button {
  margin-right: 8px;
}

/* For the file input inside Passage Document */
.input_field.passage_document_group .file_input_container input {
  width: 100%;
  font-size: 14px;
}

/* File input wrapper for Question Document */
.question_document_group {
  width: 416px;
  height: 110px;
  position: absolute;
  top: 260px;
  left: 455px;
  gap: 8px;
}

/* Container for the file input field */
.file_input_container input[type='file'] {
  width: 100%;
}

/* File input wrapper for Solution Document */
.solution_document_group {
  width: 416px;
  height: 110px;
  position: absolute;
  top: 380px;
  left: 45px;
  gap: 8px;
}

/* File input wrapper for Mapping Sheet */
.mapping_sheet_group {
  width: 416px;
  height: 110px;
  position: absolute;
  top: 380px;
  left: 455px;
  gap: 8px;
}

/* Wrapper for Cancel and Import buttons */
.action_buttons {
  position: absolute;
  top: 500px;
  left: 45px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

/* Style for the Import button */
.import_button {
  width: 160px;
  height: 50px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background-color: #00abfb;
  color: white;
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 16px 40px 0px #7090b033;
}

.import_button:hover {
  background-color: #00abfb;
}

.import_button.disabled {
  background-color: #d6d6d6;
  color: #a0a0a0;
  cursor: not-allowed;
  pointer-events: none;
}

.import_button.loading_class {
  background-color: #00abfb; 
  color: #fff; 
  cursor: not-allowed; 
}

/* Style for the Import Questions h2 text */
.import_title {
  width: 200px;
  height: 26px;
  position: absolute;
  left: 45px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #000;
}

/* Style for the Cancel button */
.cancel_button {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  display: flex;
  align-items: center !important;
  justify-content: center;
  text-decoration: underline;
}

.file_type_text {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
  width: 416px;
  height: 18px;
}

.import_back_button {
  cursor: pointer;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 4px;
  padding: 15px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.file_input_header {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 416px;
  height: 50px;
  gap: 16px;
}

.file_input_header label {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}

.sample_file_button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #00abfb;
  gap: 2px;
}

.sample_file_button svg {
  width: 24px;
  height: 24px;
}

/* Target only the <p> tag with class "docx-support-text" */
.docx_support_text {
  font-size: 14px;
  color: #666;
  margin: 0px;
}

.file_input_container {
  position: relative;
}

.file_name_text {
  margin-top: 8px;
  font-size: 14px;
  color: #231f20;
  width: 283px;
  height: 18px;
  gap: 0px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.file_input {
  width: 117px;
  height: 44px;
  gap: 0px;
  border-radius: 16px 0px 0px 0px;
  opacity: 0px;
}

.nameLabel,
.questionBankLabel {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 10px !important;
}
.file_input_icon{
  cursor: pointer;
  border: none;
  background: none;
  margin: 0px !important;
  padding: 0px;
}
.custom_error_toast {
  color: #b94a48 !important;
  background-color: #f2dede !important;
  font-weight: bold;
}

.required_name,
.required_questionbank {
  color: #ff5757;
}

#name {
  font-size: 12px;
}

/* Scoped Modal Styles */
.import-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.import-modal .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.import-modal .modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.import-modal .confirm-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.import-cancel-button {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Scoped Spinner */
.import-modal .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #007bff; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Scoped Overlay */
.import-modal .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.svg-download-div {
  margin-top: 6px;
}

.selectedColor.selected {
  color: #272727 !important; /* Color when selected */
}

.select_qb_text {
  color: #8e9eab !important; 
}

.custom_question_bank.selected {
  color: #272727; /* Color when an option is selected */
}

.custom_question_bank option {
  color: #272727; /* Default color for all options */
}

.custom_question_bank option:not(:disabled) {
  color: #272727; /* Color for selectable options */
}

/*Reminder Modal css*/
.reminder_modal {
  position: absolute;
  top: 0;
  left: 50%;  
  transform: translateX(-50%);  
  width: 30% !important;
  background-color: white;
  height: 252px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: flex-start; 
  box-shadow: 0px 4px 12px 0px #00000040;
  padding: 0px 25px 15px 25px;
}

.reminder_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px; 
  box-shadow: 0px 8px 24px 0px #7090B01A;
}

.reminder_modal_header p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.reminder_modal_body {
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #212529;
  margin-bottom: 20px; 
}

.reminder_modal_buttons {
  width: 196px; 
  max-width: 200px; 
  height: 56px;
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  background: #00abfb;
  border: 1px solid #00abfb;
  color: #fefefe;
  box-shadow: 0px 16px 40px 0px #7090b033;
  cursor: pointer;
  margin-left: auto; 
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .reminder_modal {
    width: 90%; 
    height: auto; 
    max-width: 100%; 
  }

  .reminder_modal_header p {
    font-size: 14px; 
  }

  .reminder_modal_body {
    font-size: 14px; 
  }
}
.modalClose {
  cursor: pointer;
}

@media (min-width: 768px) {
  .reminder_modal {
    width: 50%; 
  }
}

@media (min-width: 1024px) {
  .reminder_modal {
    width: 40%; 
  }
}
