@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

/*** BASIC SETTINGS PAGE STYLING ***/

.test_creation_form {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  font-family: 'IBM Plex Sans', sans-serif;
}

.testContainer {
  background-color: #fff;
  width: 95%;
  height: 100%;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 24px 0px #7090b026;
  font-family: 'IBM Plex Sans', sans-serif;
}

.test_creation_form .testContainer .test_top_content_table {
  width: 100%;
  height: auto;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 18px 18px 0px 0px;
  box-shadow: 0px 8px 24px 0px #7090b026;
  font-family: 'IBM Plex Sans', sans-serif;
}

.test_creation_form .testContainer .test_top_content_table .table_title {
  height: max-content;
  gap: 27px;
  display: flex;
  width: 48%;
  margin-left: 20px;
  align-items: center;
}

.test_creation_form .testContainer .test_top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-weight: 400;
  line-height: 25px;
  width: max-content;
}

.test_creation_form .testContainer .test_top_content_table .table_title_right_options {
  margin-right: 2rem !important;
  font-size: 16px;
  color: #272727;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.test_creation_form .testContainer .test_top_content_table .table_title_right_options .saveDraft {
  text-decoration: underline;
  color: #00abfb;
}

.test_creation_form .testContainer .iconBox {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 8px 24px 0px #7090b026;
  height: 36px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.test_creation_form .testContainer .test_top_content_table .table_title_right_options .publishTestButton {
  background: #c7ccd1;
  border: none;
  height: 46px;
  width: 150px;
  box-shadow: 0px 16px 40px 0px #7090b033;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  margin-left: 2em;
  gap: 10px;
  cursor: pointer;
}

.test_creation_form .testContainer .test_top_content_table .table_title_right_options .publishTestButtonOn {
  background: #2b8ced;
  border: none;
  height: 46px;
  width: 150px;
  box-shadow: 0px 16px 40px 0px #7090b033;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  margin-left: 2em;
  gap: 10px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.underline_active {
  border-bottom: 5px solid #00abfb;
}

/*** actual from area **/

.test_creation_form .testContainer .masterTestCreation {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
}
.masterTestCreation::-webkit-scrollbar {
  width: 10px; 
  height: 10px;
}
.masterTestCreation::-webkit-scrollbar-thumb {
  background-color:#DADCE0;
  border-radius: 4px;
}

.masterTestCreation::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea {
  width: 95%;
  height: 90vh;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea::-webkit-scrollbar {
  display: none;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop {
  margin-bottom: 24px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 5px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .template_drop_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 370px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}
.selectOption {
  color: #8E9EAB;
}

/* Style for all other options */
#question option:not(:first-child) {
  color: black;
}

.red_mendantory {
  color: red;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .static_drop_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  width: 370px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  background: linear-gradient(0deg, #ecf3f5, #ecf3f5), linear-gradient(0deg, #f6f7fb, #f6f7fb);
  border: 1.5px solid #ecf3f5;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .bound {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 570px;
  height: 50px;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .search_listing .input_search input,
.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .templateDrop_item .templateGroup .search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.hrLine {
  margin-top: 2.4em;
  color: #ecf3f5;
  border: 2px solid #ecf3f5;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .pageTwoContainer {
  width: 100%;
  height: 100%;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop .pageTwoContainer .testMarklabel {
  font-weight: 500;
  height: 30px;
  margin: 0px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .info {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .templateDrop_item .templateGroup .score_drop_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #ECF3F5;
  width: 178px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 10px;
  padding-left: 15px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .icon_text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoContainer .marketLogic {
  font-weight: 500;
  font-size: 14px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoContainer .levels {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  gap: 2px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.penalty_range {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.penalty_range .penalty_drop_filter {
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 178px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  text-align: center;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoContainer .penalty_range .fullbg {
  background: #ecf3f5;
  text-align: right;
  padding-right: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.penalty_range .setPenalty {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-weight: 500;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.penalty_range .drop_marks {
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 178px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  text-align: right;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.penalty_range .setPenalty .pp {
  margin-top: -1em;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .threshold {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: #00abfb;
  font-size: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .threshold h5 {
  text-decoration: underline;
}

.setter {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.screenNum {
  display: flex;
  flex-direction: column;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.screenNum .timer {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 2px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.screenNum .timer_input {
  border: 1.5px solid #ECF3F5;
  width: 178px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea
/* .pageTwoContainer */
.screenNum .timer input[type="number"]::-webkit-inner-spin-button,
.test_creation_form .testContainer .masterTestCreation .testCreationArea

/* .pageTwoContainer */
.screenNum .timer input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button;
  width: 20px;
  height: 51px;
  border-radius: 12px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoContainer .screenNum .typeArea {
  width: 475px;
  height: 110px;
  border: 1.5px solid #ecf3f5;
  border-radius: 12px;
  background: linear-gradient(0deg, #ecf3f5, #ecf3f5), linear-gradient(0deg, #ffffff, #ffffff);
  outline: none;
  overflow-wrap: break-word;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 10px;
  resize: none;
}

.typeArea {
  resize: none;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoContainer .screenNum .typeArea .typeArea::placeholder {
  display: flex;
  padding-top: -2em;
}

.test_creation_form .performBT {
  display: flex;
  gap: 20px;
}

.test_creation_form .performBT .common {
  width: 197px;
  height: 56px;
  border: 1px solid #00abfb;
  color: #fff;
  border-radius: 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.test_creation_form .performBT .btOne {
  background: transparent;
  color: #00abfb;
}

.test_creation_form .performBT .btTwo {
  background: #00abfb;
}

/*** SECTIONS SCREEN CSS ***/

.test_creation_form .testContainer .masterTestCreation .testCreationArea .containGroup {
  display: flex;
  gap: 10px;
  align-items: center;
}

.bisect {
  display: flex;
  width: 60rem;
}

.bisectAdd {
  display: flex;
  width: 60rem;
  gap: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .containGroup .commGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 56px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 8px 24px 0px #7090b026;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .containGroup .commGroup:focus {
  background-color: #00abfb;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .threshold .removeGroupButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 48px;
  border-radius: 12px;
  border: none;
  font-size: 10px;
  cursor: pointer;
  margin: 0 2rem 0 0;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .section_form {
  width: 95.5%;
  height: auto;
  border: 1px solid #e2ecf080;
  box-shadow: 0px 4px 6px 0px #0000000f;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.groupName {
  display: flex;

  flex-direction: column;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .section_form .groupDrop {
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 376px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .section_form .typeArea {
  width: 475px;
  height: 110px;
  border: 1.5px solid #ecf3f5;
  border-radius: 12px;
  outline: none;
  overflow-wrap: break-word;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoSection {
  width: 98.5%;
  height: 275vh;
  border: 1px solid #e2ecf080;
  box-shadow: 0px 4px 6px 0px #0000000f;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoSection .bound .verbalBox {
  width: 376px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoSection .bound .templateDrop .varc {
  width: 178px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoSection .sectionsHR {
  width: 100%;
  background: #ecf3f5;
  border: 2px solid #ecf3f5;
  margin-top: 2em;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .pageTwoSection .bound .templateDrop .templateDrop_item .templateGroup .fullBgmarks {
  background: #ecf3f5;

  padding-right: 10px;
}

.questionBank {
  width: 100%;
  margin-top: 10px;
  height: auto;
  border: 1px solid #e2ecf080;
  box-shadow: 0px 4px 6px 0px #0000000f;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.questionBank .headerBank {
  display: flex;
  padding-left: 10px;
}

.headerBank h4 {
  flex: 2;
}
.addNewQuestionBank{
  margin: 15px 1rem 0 0 !important;
}

.questionBank .headerBank .addNewQuestionBt {
  width: 334px;
  height: 56px;
  border: 1.5px solid #00abfb;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
  border-radius: 18px;
  background: transparent;
  padding-left: 10px;
  margin: 15px 2rem 0 0;
}

.addNewQuestionBt span {
  font-weight: 700;
  color: #00abfb;
}

.questionBank .handleAttempt {
  display: flex;
  gap: 5px;
  font-size: 14px;
  margin-top: -20px;
  padding-left: 10px;
}

.sub_head_content_table {
  height: 55vh;
  overflow-y: scroll;
}
.sub_head_content_table::-webkit-scrollbar {
  width: 10px; 
  height: 10px;
}
.sub_head_content_table::-webkit-scrollbar-thumb {
  background-color:#DADCE0;
  border-radius: 4px;
}

.sub_head_content_table::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.questionBank .sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 13px;
  padding-right: 13px;
}

.questionBank .sub_head_content_table table {
  border-spacing: 0px !important;
  width: 100%;
}

.sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: left;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.questionBank .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 25px;
}

.questionBank .sub_head_content_table table thead .first {
  height: 28px;
}

.questionBank .sub_head_content_table table thead .second {
  height: 28px;
  padding-left: 15px;
}

.questionBank .sub_head_content_table table thead .third {
  height: 28px;
  padding-left: 15px;
}

.questionBank .sub_head_content_table table thead .fourth {
  height: 28px;
  padding-left: 15px;
}

.questionBank .sub_head_content_table table thead .fifth {
  height: 28px;
}

.questionBank .sub_head_content_table table thead th span {
  margin-right: 4px;
}

/**** question bank **/
.questionBank .changesEvent {
  height: 36px;
  width: 109px;
  border-radius: 4px;
  background: linear-gradient(0deg, #fff9e8, #fff9e8), linear-gradient(0deg, #8e9eab, #8e9eab);
  border: 0.3px solid #8e9eab;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
}
.QuasiChangeEvent{
  height: 30px !important;
  width: 48px !important;
}
.questionBank .srNo {
  text-align: center;
  font-weight: 500;
  align-items: center;
  width: 100px;
}

.questionBank .srNo .srContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding-left: 5px;
}

.actions_question {
  position: relative;
}

.action_button {
  border: none;
  display: flex;
  justify-content: start;
  width: 110px;
  padding: 7px;
  box-shadow: 0 8px 24px 0 #7090b026;
  border-radius: 18px;
  position: absolute;
  right: 15px;
  z-index: 50;
  background-color: white;
  top: 12px;
}
.questionListLabel{
  font-weight: 500;
  font-size: 18px;
  margin: 0px;
  margin-top: 10px;

}
.action_button .edit,
.duplicate {
  padding: 5px;
}
.questionTags{
  width: 350px;
}
.browseQuestionTags{
  width: 280px;
  white-space: normal;
  overflow-wrap: break-word;
}
.questionBank .question_id_tag {
  display: inline-flex;
  margin-left: 13px;
  position: relative;
  align-items: center;
}

.questionBank .question_id_tag .id {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-decoration: underline;
  line-height: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  margin-right: 25px;
  cursor: pointer;
}

.questionBank .question_id_tag .tag {
  text-align: left;
}

.questionBank .question_id_tag .tag p {
  font-size: 12px;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  margin-top: 0px;
}

.questionBank .question_id_tag .tag span {
  font-size: 10px;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  background-color: #e9f8ff;
  border-radius: 50px;
}

.questionBank .changeCheckbox {
  display: flex;
  gap: 10px;
  padding-left: 20px;
  justify-content: center;
}

.questionBank .general_button {
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}

.questionBank .general_tag {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.questionBank .author_tag {
  font-size: 12px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  white-space: normal;
  overflow-wrap: break-word;
}
.authorTag{
  width: 140px;
  white-space: normal;
  overflow-wrap: break-word;
}
.academicYear{
  width: 115px;
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  white-space: normal;
  overflow-wrap: break-word;
}
.created_date{
  width: 100px;
  white-space: normal;
  overflow-wrap: break-word;
}
.lod{
  width: 80px;
  white-space: normal;
  overflow-wrap: break-word;
}
.testType{
  width: 120px;
  font-size: 12px;
    line-height: 20px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    text-align: left;
}
.questionBank .created_date,
.lod {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: left;
}

.questionBank .sectionFooter {
  background: linear-gradient(0deg, rgba(226, 236, 240, 0.5), rgba(226, 236, 240, 0.5)),
    linear-gradient(0deg, #fafafb, #fafafb);
  border: 1px solid #e2ecf080;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-direction: row;
  gap: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.questionBank .sectionFooter .footer_content {
  display: flex;
  align-items: center;
  width: 20%;
  gap: 10px;
  margin-left: 20px;
}

.questionBank .sectionFooter .footer_content_mid {
  margin-left: 20px;
}

.questionBank .sectionFooter .footer_content_last {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
  margin-left: 20px;
  width: 55%;
  overflow-x: scroll;
}

.questionBank .sectionFooter .footer_content_start {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
  margin-left: 20px;
  width: 40%;
  overflow-x: scroll;
}

.questionBank .sectionFooter .footer_content_mid .closeIds {
  min-width: 105.59px;
  height: 44px;
  background: #e5e8ec;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerDeleteLabel{
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  left: 20px;
}    
.questionBank .sectionFooter .footer_content_right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 32%;
  position: relative;
}

.questionBank .sectionFooter .footer_content_right .drop_score {
  width: 214px;
  height: 56px;
  background: #00abfb;
  box-shadow: 0px 16px 40px 0px #7090b033;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  color: #fff;
  cursor: pointer;
}

.logics_input {
  position: absolute;
  background-color: white;
  border: 1px solid white;
  box-shadow: 0px 0px 10px rgb(199, 199, 199);
  border-radius: 10px;
  padding: 10px;
  width: 180px;
  top: -150px;
  right: 20px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.logics_input::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 6px;
  cursor: pointer;
}
.logics_input::-webkit-scrollbar {
  height: 7px; /* Set the height of the horizontal scrollbar */
}
.logics_input::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
 .logics_input .new {
  color: #00abfb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

 .logics_input .QuasiScoring .QuasiInputs {
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 50px;
  gap: 5px;
  padding: 5px;
  font-size: 10px;
}
.logics_input .scoring .inputs {
  align-items: center;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  gap: 30px;
  justify-content: left;
  padding: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

 .logics_input .scoring {
  padding: 10px;
  font-size: 10px;
}
.logics_input .QuasiScoring {
  padding: 10px;
  font-size: 10px;
  width: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/*** ADVANCE SETTINGS SCREEN **/

.test_creation_form .testContainer .editOption {
  background: #00abfb;
  transition: 0.15s ease-out;
  color: #fff;
}

.test_creation_form .testContainer .wrapperIcon {
  display: flex;
  box-shadow: 0px 8px 24px 0px #7090b026;
  border-radius: 16px;
  width: -moz-fit-content;
  width: 130px;
  height: 35px;
  justify-content: center;
  align-items: center;
  background: #f4f5f6;
  color: black;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.test_creation_form .testContainer .test_top_content_table .table_title_right_options_someLeft {
  margin-left: 27em;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .AdvanceSettingsContainer {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .switching {
  display: flex;
  align-items: center;
  gap: 20px;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .switching .wrapperIcon .iconBoxs {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
}

.groupSettingsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 75vh;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .groupSettings {
  width: 100%;
  height: fit-content;
}

.test_creation_form .testContainer .masterTestCreation .testCreationArea .fullbg_section {
  background: #ecf3f5;

  padding-right: 10px;
}

/*** filter***/
.test_creation_form .filterContainer {
  background-color: #ffffff;
  width: 95%;
  height: 90vh;
  margin-top: 20px;
  border-radius: 18px 18px 0px 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 24px 0px #7090b026;
  font-family: 'IBM Plex Sans', sans-serif;
}

.test_creation_form .filterContainer .test_top_content_table {
  width: 98%;
  height: auto;
  display: flex;
  box-shadow: 0px 8px 24px 0px #7090b026;
  position: relative;
}

.test_creation_form .filterContainer .test_top_content_table .table_filter_right_options {
  font-size: 16px;
  color: #272727;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  gap: 30px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.masterfilterCreation {
  position: relative;
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: column;
}

.filterCreationArea {
  position: absolute;
  width: 98%;
  height: 90%;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;
  overflow-y: scroll;
  margin-top: 10px;
  padding: 10px;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 30px;
}

.filterCreationArea::-webkit-scrollbar {
  display: none;
}

.sectionContainer {
  width: 100%;
  height: auto;
  display: flex;
}

.sectionContainer .workArea1 {
  flex: 0 0 60%;
}

.sectionContainer .workArea2 {
  flex: 0 0 40%;
}
.subjectLabel{
  font-size: 14px;
  font-weight: 500;
}
.sectionContainer .workArea2 .id_filter_listing {
  height: 100% !important;
}

.sectionContainer .workArea1 .drop {
  width: 80%;
  display: flex;
  gap: 20px;
}

.sectionContainer .workArea1 .drop .drop_item {
  width: 50%;
}

.sectionContainer .workArea1 .drop .drop-item .subject p {
  font-size: 5px;
}

.sectionContainer .workArea1 .drop .tags {
  width: 65%;
}

.sectionContainer .workArea1 .drop .drop-item .subject {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 5px;
}

.header_drop select {
  width: 100%;
  height: 48px;
  border: 1.5px solid #c6ccd2;
  border-radius: 14px;
  padding-left: 10px;
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.header_drop_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sectionContainer .workArea1 .tags {
  width: 80%;
}

.sectionContainer .workArea1 .tags .subject {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 5px;
}

.sectionContainer .workArea1 .tags .subject p {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.filterSearch {
  height: 100%;
  display: flex;
  width: 90%;
  position: relative;
}

.filterSearch input {
  border: none;
  outline: none;
  width: 100%;
  height: 45px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.filterSearch input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.filter_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #d9d1d1;
  width: 434px;
  height: 50px;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.id_filter_listing {
  height: 415px;
  border: 1.5px solid #ecf3f5;
}

.filter_bound {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.filter_templateDrop {
  margin-bottom: 20px;
}

.filter_templateGroup {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 5px;
}

.filter_template_drop_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 178px;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}

.filter_th {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  padding-top: 5px;
}

.input_search .selected_tags {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 2px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cancel_button {
  float: right;
}

.cancel_button button {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_input_primary {
  top: 55px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  width: 90%;
  height: 20vh;
  padding: 10px;
  border-radius: 10px;
  z-index: 50;
  overflow-y: scroll;
  position: absolute;
}

.list_input_primary div {
  padding: 8px 5px;
}

.list_input_primary div:hover span {
  background-color: #00abfb;
  color: white;
}

.list_input_primary div span {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.custom_checkbox {
  position: relative;
  display: flex;
  cursor: pointer;
  border: 1px solid #55ca5f;
  width: 25px;
  border-radius: 6px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.custom_checkbox input[type='checkbox'] {
  display: none;
}

.custom_checkbox .check_icon {
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.custom_checkbox input[type='checkbox']:checked~.check_icon {
  opacity: 1;
}

.custom_checkbox input[type='checkbox']:checked~.custom_checkbox {
  border: none;
}

.penaltyDelete {
  border: none;
  padding: 10px;
  margin: 10px;
  background-color: #f6f7fb;
  text-align: center;
  color: black;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 18px;
  cursor: pointer;
}

/* _______________________________________ Test Listing design ______________________*/
.test_listing_page {
  width: 100%;
  height: 85vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem 0 0 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

.test_listing_page .upperSection {
  width: 95%;
  height: 10vh;
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  gap: 3rem;
}

.test_listing_page .mainSection {
  width: 95%;
  height: 67vh;
  background: #ffffff;
  border-radius: 18px;
}
.mainSectiontableWrap{
  height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.mainSectiontableWrap::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 6px;
  cursor: pointer;
}
.mainSectiontableWrap::-webkit-scrollbar {
  height: 7px; /* Set the height of the horizontal scrollbar */
}
.mainSectiontableWrap::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.testPaperListingPagination{
  width: 87vw;
  position: relative;
  top: 83px;
  margin-top: 15px;
}
.test_listing_page .upperSection .text {
  font-weight: 500;
}

.test_listing_page .upperSection .midValues {
  display: flex;
  align-items: center;
  margin: 0 0 0 20rem;
}

.test_listing_page .upperSection .midValues .search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 8px 24px 0px #7090b026;
  width: 400px;
  height: 40px;
  border-radius: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #ffffff;
}

.test_listing_page .upperSection .midValues .search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.test_listing_page .upperSection .midValues .search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.test_listing_page .upperSection .midValues .search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 232px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.mainSection table {
  width: 100%;
  border-collapse: collapse;
}

.mainSection table thead {
  box-shadow: 0px 8px 24px 0px #7090b026;
  height: 48px;
}
.mainSection table thead .first {
  width: 7%;
}

.mainSection table thead .second {
  width: 14%;
  padding-left: 1%;
}

.mainSection table thead .third {
  padding-left: 1%;
  width: 12%;
}

.mainSection table thead .fourth {
  padding-left: 1%;
  width: 15%;
}

.mainSection table thead .fifth {
  padding-left: 1%;
  width: 17%;
}

.mainSection table thead .sixth {
  padding-left: 1%;
  width: 12%;
}

.mainSection table thead .seventh {
  padding-left: 1%;
  width: 22%;
}

.mainSection table thead .eighth {
  padding-left: 1%;
  width: 11%;
}

.mainSection table thead .ninth {
  padding-left: 1%;
  width: 20%;
}
.mainSection thead tr,
.mainSection tbody tr {
  display: flex;
  width: 100%;
}
.mainSection thead th{
  font-weight: 500 !important;
  height: 45px !important;
}
.mainSection thead th,
.mainSection tbody td {
  text-align: center;
  display: flex;
  padding-left: 3%;
  height: auto;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  align-items: center;
}
.testId{
    word-break: break-word;
    white-space: normal;
    width: 67px;
}
.mainSection th:nth-child(2) {
  justify-content: flex-start;
  padding-left: 0px;
}

.mainSection td:nth-child(2) {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  white-space: normal;
  width: 11%;
  text-align: left;
  justify-content: center;
}

.mainSection table thead th svg {
  cursor: pointer;
}

.mainSection td:nth-child(3) {
  word-break: break-word;
  white-space: normal;
  width: 11%;
  text-align: left;
  padding: 1em;
}

.mainSection td:nth-child(4) {
  word-break: break-word;
  white-space: normal;
  width: 11%;
  text-align: left;
  padding: 1em;
}

.mainSection td:nth-child(5) {
  display: flex;
  word-break: break-word;
  white-space: normal;
  align-content: center;
  align-items: flex-start;
  width: 17%;
  gap: 5px;
  text-align: left;
  padding: 1em;
  flex-wrap: wrap;
}
.mainSection td:nth-child(6) {
  word-break: break-word;
  white-space: normal;
  width: 11%;
  text-align: left;
  padding: 1em;
}

.mainSection td:nth-child(7) {
  word-break: break-word;
  white-space: normal;
  width: 21%;
  text-align: left;
  padding: 1em;
}

.mainSection td:nth-child(8) {
  word-break: break-word;
  white-space: normal;
  width: 17%;
  text-align: left;
  padding: 0px;
}
.mainSection td:nth-child(9) {
  word-break: break-word;
  white-space: normal;
  width: 12%;
  text-align: left;
  padding: 1em;
}

.mainSection table tbody tr {
  height: auto;
  border: 0.5px solid #e2ecf0;
}

.general_button {
  width: fit-content;
  height: auto;
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 14px;
  padding: 4px 6px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #212529;
}

.template_button {
  width: fit-content;
  height: auto;
  border-radius: 50px;
  background: #e9f8ff;
  border: none;
  font-size: 10px;
  padding: 4px 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #212529;
}

.user_management_table_body_content {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.user_management_table_body_content .in_review {
  position: relative;
  height: 4vh;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 65px;
  justify-content: center;
  text-transform: capitalize;
  background: #fff9e8;
  color: #ffcf00;
  border: 0.3px solid #ffcf00;
  font-size: x-small;
}

.user_management_table_body_content .published {
  position: relative;
  height: 4vh;
  width: 65px;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background: #e9fcf1;
  color: #26ab5b;
  border: 0.3px solid #26ab5b;
  font-size: x-small;
}

.user_management_table_body_content .draft {
  position: relative;
  height: 4vh;
  width: 65px;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background: #ffffff;
  color: #8e9eab;
  border: 0.3px solid #8e9eab;
  font-size: x-small;
}

.multiquestionbutton {
  background-color: #00abfb;
  color: white;
  width: 185px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: 0px 5px 10px #e8dcdc;
  cursor: pointer;
}

.multiquestionbuttonBlue {
  background-color: #ffffff;
  color: rgb(9, 7, 7);
  width: 185px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: 0px 5px 10px #e8dcdc;
  cursor: pointer;
}

/**__________________________________________ Modal design __________________*/
.threePin {
  position: absolute;
  top: 50px;
  right: -55px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 16px;
  z-index: 1000;
  width: 100px;
  height: auto;
  border: none;
  border-radius: 18px;
  box-shadow: 0px 8px 24px 0px #7090b026;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editPops {
  display: flex;
  gap: 10px;
}

.editPop {
  display: inline;
}

.editPops .action {
  cursor: pointer;
  transition:
    color 0.5s ease-in-out,
    transform 0.5s ease-in-out;
}

.editPops .action:hover {
  color: #00abfb;
  transform: scale(1.1);
}

.confirmation_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation_modal_content {
  background-color: white;
  padding: 20px;
  text-align: center;
  width: 55%;
  height: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 30px;
  border: none;
}

.confirmation_modal_content p {
  font-size: 24px;
  font-weight: 600;
  color: #272727;
}

.confirmDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.confirmDiv h5 {
  text-decoration: underline;
  font-size: 18px;
}

.confirmDiv button {
  width: 197px;
  height: 56px;
  background: #00abfb;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 18px;
}

.break {
  box-shadow: 0px 8px 24px 0px #7090b026;
  border-radius: 16px;
  height: max-content;
  background-color: #ffffff;
}

.break_two {
  box-shadow: 8px 8px 24px 0px #7090b026;
  border-radius: 16px;
  background-color: #ffffff;
}

.advanced_filter {
  padding-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.advanced_filter .actual_filter {
  margin-top: -10px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.actual_filter .dropTasks {
  width: 178px;
  background: url('../images/down.svg') no-repeat right center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 40px 25px;
}

.dropTasks select {
  color: #8e9eab;
}

.reverse_drop {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  cursor: pointer;
}

.subject textarea {
  font-family: 'IBM Plex Sans', sans-serif;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../images/date.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 20px 25px;
  cursor: pointer;
}

.flow::-webkit-scrollbar {
  display: none;
}

.flow {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.reverse_drop svg {
  transition: transform 0.3s ease-in-out;
}

.reverse_drop:active svg {
  transform: rotate(360deg);
}

.stored {
  border: 1px solid #00abfb;
  padding: 10px;
  margin: 10px;
  background-color: #e9f8ff;
  text-align: center;
  flex: 1 1 auto;
  color: black;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 18px;
}

.multiquestionbutton-selected {
  background-color: blue;
  color: white;
}

.multiquestionbutton-unselected {
  background-color: grey;
  color: black;
}

/* __________________________ Confirm modal _____________________*/

.mixed {
  width: 600%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #55ca5f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading_message {
  font-family: 'IBM Plex Sans', sans-serif;
}

.tooltip_container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #565656;
  color: #fff;
  padding: 5px 20px;
  border-radius: 15px;
  z-index: 9999;
  left: 50%;
  transform: translateX(-73%);
  white-space: nowrap;
  visibility: visible;
  opacity: 0.9;
  transition: opacity 0.3s;
  top: 23px;
}

.testPaperPagination {
  width: 94vw;
  height: 10vh;
  position: relative;
  top: 40px;
  margin: 10px 0px;
}
.quasiTestPaperPagination{
  width: 75vw;
  height: 10vh;
  position: relative;
  top: 40px;
  margin: 10px 0px;
}

.subjectWrap,
.tagsWrap,
.QuestionAuthWrap {
  display: flex;
  flex-wrap: wrap;
}
.isViewMainContainer{
  pointer-events: none;
}
/* Quasi section */
.quasiSectionCreation {
  font-family: 'IBM Plex Sans', sans-serif;
}
.quasiSectionCreation .detailsConfigureSet{
  margin-top: 18px !important;
}
.quasiSectionCreation .sectionHeading {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.quasiSectionCreation .templateGroup {
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-left: 0 !important;
}

.quasiSectionCreation .marketLogic {
  font-weight: 500;
}

.quasiSectionCreation .sectionRoundInfo {
  font-family: 'IBM Plex Sans', sans-serif;
  padding-left: 6px;
}

.quasiSectionCreation .sectionTemplateRoundInfo {
  display: flex;
}

.quasiSectionCreation input[type="text"],
.quasiSectionCreation input[type="number"] {
  height: 30px;
  width: 36px;
  border: none ;
  box-shadow: none;
  border-radius: 4px;
  padding: 0 4px;
}
.quasiSectionCreation select {
  border-radius: 12px !important;
  padding: 0 15px !important;
  border: 2px solid #ECF3F5 !important;
  box-shadow: none !important;
  cursor: pointer;
}

.quasiSectionCreation input::placeholder {
  color: #8E9EAB;
}

.quasiSectionCreation .fullBgmarks {
  margin-right: 20px;
}

.quasiSectionCreation .editInput {
  background: transparent;
  border: none !important;
  padding: 0;
}

.quasiSectionCreation .sectionSets {
  display: flex;
}

.quasiSectionCreation .sectionSetsLeft {
  width: 100%;
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'IBM Plex Sans', sans-serif;
}
.quasiSectionCreation .sectionSetsLeft .multiquestionbutton, .quasiSectionCreation .sectionSetsLeft .multiquestionbuttonBlue{
  width: 150px !important;
}
.quasiSectionCreation .setButton {
  text-align: left;
  padding: 12px 15px;
  background-color: #FAFAFA;
  border: 1px solid #C7CCD1;
  cursor: pointer;
  font-family: 'IBM Plex Sans', sans-serif;
}

.quasiSectionCreation .setButton.active {
  background: #272727;
  color: white;
}

.quasiSectionCreation .addSetButton {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 14px 3px 3px;
  margin-top: 15px;
}
.quasiSectionCreation .addSetContainer{
  margin: 20px 0 18px;
}
.quasiSectionCreation .addInstrButton {
  margin-bottom: 15px;
}

.quasiSectionCreation .sectionSetsRight {
  flex: 1;
  padding: 0 8px 20px;
  background-color: #fff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  width: 100%;
}

.quasiSectionCreation .fieldGroup {
  margin-bottom: 18px;
}

.quasiSectionCreation .fieldGroup label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
}
.detailsPage{
  font-size: 16px;
  color: #272727;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.editPdfButton{
  margin-right: 30px !important;
}
.quasiSectionCreation .fieldGroup input[type="text"],
.quasiSectionCreation .fieldGroup input[type="number"] {
  width: 178px;
  height: 50px;
  text-align: left;
  border: 2px solid #ecf3f5 !important;
  border-radius: 12px !important;
  box-shadow: none !important;
  padding: 0 15px !important;
}

.quasiSectionCreation .scoreTable table {
  width: 65%;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.quasiSectionCreation .scoreTable th,
.quasiSectionCreation .scoreTable td {
  padding: 8px;
  text-align: center;
}

.quasiSectionCreation .scoreTable td {
  border: 1px solid #ccc;
}

.quasiSectionCreation .scoreTable tr {
  margin-bottom: 10px;
}

.quasiSectionCreation .scoreTable td:first-child {
  background: #F6F7FB;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}

.quasiSectionCreation .scoreTable td:last-child {
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}

.quasiSectionCreation .scoreTable td:last-child input {
  border: none !important;
}

.quasiSectionCreation .scoreTable td:last-child input:focus {
  outline: none !important;
}

.quasiSectionCreation .removeSetButton {
  padding: 10px;
  color: #272727;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.quasiSectionCreation .setNameSection {
  display: flex;
  gap: 25px;
  width: 100%;
  position: relative;
}

.quasiSectionCreation .addSetText {
  margin: 14px 0;
}
.quasiSectionCreation .set_remove{
  position: absolute;
  right: 0;
}
.quasiSectionCreation .setActive{
  background: #000;
  color: #fff;
}
.quasiSectionCreation .removeGroupButton {
  color: #272727;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;
  height: 48px;
  border-radius: 12px;
  border: none;
  font-size: 12px !important;
  cursor: pointer;
  margin: 0 !important;
}

.quasiSectionCreation .verbalBox {
  width: 95% !important;
}
.quasiSectionCreation .add_btn{
  margin: 0 !important;
}

.quasiSectionCreation .sectionName {
  width: 42%;
  margin-bottom: 8px !important;
}

.quasiSectionCreation .removeSet {
  width: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.quasiSectionCreation .sectionIdentifier {
  width: 40%;
  margin-bottom: 8px !important;
}

.quasiSectionCreation .sectionIdentifier select {
  width: 80% !important;
  height: 54px !important;
  cursor: pointer;
}
select {
  cursor: pointer;
}
.quasiSectionCreation .sectionTimer label {
  color: #272727;
  font-size: 14px !important;
  font-family: 'IBM Plex Sans', sans-serif;
}
.quasiSectionCreation .timer{
  gap: 25px !important;
}
.quasiSectionCreation .instrTimer{
  gap: 12px !important;
}
.quasiSectionCreation .unattempted_penalty {
  margin-right: 0 !important;
}

.quasiSectionCreation .randmizeQue {
  display: flex;
  align-items: center;
}
.quasiSectionCreation .randmizeQue label{
  margin-bottom: 0 !important;
  margin-right: 12px;
}
.quasiSectionCreation .check_btn {
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
}

.quasiSectionCreation .sectionTestCreation {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.quasiSectionCreation .sectionTestCreation .containGroup {
  width: 84%;
  flex-wrap: wrap;
}
.quasiSectionCreation .group_icon{
  width: 18%;
}
.quasiSectionCreation .pageTwoSection {
  width: 96% !important;
  height: auto !important;
  padding: 0px 15px 30px !important;
  margin-top: 16px;
  border: 2px solid #e2ecf080 !important;
}

.quasiSectionCreation .filter_listing input {
  border: none !important;
  box-shadow: none !important;
  border-radius: none !important;
}
.quasiSectionCreation .iconWrapper {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}
.quasiSectionCreation .iconInfo{
  cursor: pointer;
}
.quasiSectionCreation .popup {
  position: absolute;
  top: -20px;
  left: 30px;
  background-color: #fff;
  border: 1px solid #C7CCD1;
  border-radius: 4px;
  padding: 12px;
  font-size: 13px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #212529;
  z-index: 1000;
  width: 21vw;
  line-height: 20px;
}
.markingLogic{
  outline: none;
  border: transparent;
}
.quasiSectionCreation .unattempled_penalty{
  margin-right: 0 !important;
}
.custom_radio {
  appearance: none; /* Hides default radio styling */
  width: 19px;
  height: 19px; /* Ensure height matches width */
  aspect-ratio: 1; /* Maintains a 1:1 aspect ratio */
  border: 2px solid #00ABFB;
  border-radius: 50%;
  margin-right: 6px;
  position: relative;
  cursor: pointer;
  margin-left: 0;
}

.custom_radio:checked::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #00ABFB;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom_input {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  border: none;
}
.custom_input:focus {
  outline: none;
}
.quasiSectionCreation .setcode_info{
  background: #EEFAFF;
  border-radius: 16px;
  padding: 12px 15px;
  color: #00ABFB;
  font-size: 14px !important;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
}
.quasiSectionCreation .section_form{
  width: 100% !important;
  padding: 10px 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.quasiSectionCreation .info{
  margin-right: 6px;
  font-size: 14px !important;
}
/* Duplicate test css */
.input_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.input_label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.input_field {
  padding: 8px;
  width: 230px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 24px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  line-height: 32px;
}

.AdvanceSettingslabel {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 12px;
}
.HandleTestDuration{
  background: #E9E9E9 !important;
}
.timerLabel{
  font-size: 14px;
  width: 60px;
}
.checkIcon{
  width: 25px;
  height: 25px;
  position: relative;
  right: 2px;
  bottom: 2px;
  cursor: pointer;
}
.contentTablewrap{
  padding: 15px 0px !important;
  min-height: 40px;
}
.marginTop{
  margin-top: 2%;
}