.Master_div {
  width: 87vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.master_category {
  width: 82vw;
  height: 75vh;
}

.input_search_master {
  height: 100%;
  display: flex;

  width: 100%;
  position: relative;
}

.input_search_master input {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  border-radius: 10px;
}

.search_icon {
  padding-left: 12px;
}

.category_table thead .first {
  text-align: left;
  padding-left: 4%;
  width: 20%;
}

.category_table thead .second {
  text-align: left;
  padding-left: 3%;
  width: 30%;
}
.category_table thead .third {
  text-align: center;
  padding-left: 3%;
  width: 40%;
}
.category_table table tbody tr td {
  padding-left: 4% !important;
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}
.category_table table tbody tr .category_description,
.category_tags {
  padding-left: 3% !important;
}
.questionBank_table thead .first {
  text-align: left;
  padding-left: 4%;
  width: 20%;
}

.questionBank_table thead .second {
  text-align: left;
  padding-left: 3%;
  width: 30%;
}
.questionBank_table thead .third {
  text-align: center;
  padding-left: 3%;
  width: 40%;
}
.questionBank_table table tbody tr td {
  padding-left: 4% !important;
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}
.questionBank_table table tbody tr .questionBank_description {
  padding-left: 3% !important;
}
.questionBank_table table tbody tr .questionBank_tags {
  padding-left: 3% !important;
}
.remove_master_one {
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 8px 12px;
  justify-content: center;
}

.question_bank {
  width: 82vw;
  margin-bottom: 30px;
}

.table_content_report {
  background-color: #ffffff;
  border-radius: 20px;
  margin-top: 2%;
  height: 84vh;
}

.table_content_report::-webkit-scrollbar {
  width: 10px; /* Set the width of the vertical scrollbar */
  height: 10px; /* Set the height of the horizontal scrollbar */
}
.table_content_report::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.table_content_report::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
/* style.table_content_report */

.table_content_report .top_content_table {
  width: 82vw;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .search_listing {
  width: 50%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.user_management_filter {
  justify-content: start;
}

.user_management_search_listing {
  margin: 10px 24px;
}

.add_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 108px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 245px;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.pointer {
  cursor: pointer;
}

.sub_head_content_table {
  width: 82vw;
  overflow-y: auto;
  overflow-x: hidden;
  height: 56vh;
}
.sub_head_content_table::-webkit-scrollbar {
  width: 10px; /* Set the width of the vertical scrollbar */
}
.sub_head_content_table::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.sub_head_content_table::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 13px;
  text-align: start;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  width: 82vw;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 8vh;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
  margin-right: 7px;
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.Identifier_table thead .first {
  text-align: left;
  padding-left: 4%;
  width: 20%;
}

.Identifier_table thead .second {
  text-align: left;
  padding-left: 3%;
  width: 30%;
}

.Identifier_table thead .third {
  text-align: center;
  padding-left: 3%;
  width: 40%;
}

.Identifier_table table tbody tr td {
  text-align: left;
  padding-left: 4%;
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}
.Identifier_table table tbody tr .identifier_description,
.identifier_tags {
  padding-left: 3%;
}

.LOD thead .first {
  text-align: left;
  padding-left: 4%;
  width: 80%;
}

.LOD thead .fifth {
  text-align: left;
  padding-left: 5%;
  width: 9%;
}

.LOD table tbody tr td {
  text-align: left;
  padding-left: 4%;

  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}

.test_type {
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  padding: 5px 10px;
}

.action {
  margin-right: 26px;
  position: relative;
}

.action_buttons {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgba(33, 37, 41, 0.1) 0px 4px 10px 5px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px;
  position: absolute;
  right: 52%;
  top: 60%;
  z-index: 60;
  width: 80px;
  height: 20px;
}
.action_Editbuttons,
.action_Removebuttons {
  position: relative;
  bottom: 2px;
  left: 5px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
}
.action_button .edit {
  padding: 5px 0px;
}

.action_button .duplicate {
  padding: 5px 0px;
}

.SubjectPipeline_table thead .first {
  text-align: left;
  padding-left: 4%;
  width: 12%;
}

.SubjectPipeline_table thead .second {
  text-align: left;
  padding-left: 5%;
  width: 12%;
}

.SubjectPipeline_table thead .third {
  text-align: left;
  padding-left: 5%;
  width: 12%;
}
.SubjectPipeline_table thead .fourth {
  text-align: left;
  padding-left: 5%;
  width: 20%;
}

.SubjectPipeline_table thead .fifth {
  text-align: left;
  padding-left: 3%;
  width: 32%;
}
.SubjectPipeline_table thead .sixth {
  text-align: left;
  padding-left: 3%;
  width: 20%;
}
.SubjectPipeline_table table tbody tr td {
  text-align: left;
  padding-left: 5.2%;

  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}

.SubjectPipeline_table table tbody tr .Area,
.Topic,
.subTopic,
.Dots {
  padding-left: 3% !important;
}

.SubjectPipeline_table table tbody tr .tags {
  padding-left: 1% !important;
  font-size: 11px;
}
.master_subjectPipeline {
  width: 94%;
}

.slugs_Tags {
  width: 90px;
  height: 28px;
  border-radius: 50px;
  background-color: #e9f8ff;
  border: none;
  line-height: 28px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  padding: 5px 20px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

/**** PAGINATION**/
.MasterpaginationWrap {
  width: 100%;
  margin-left: 30px;
  position: relative;
  bottom: -10px;
}

.selectedquestion {
  font-weight: 500 !important;
}
.back_button {
  border: none;
  padding: 15px 15px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.actionIconwrap {
  padding: 0px 12px;
}
button {
  cursor: pointer;
  padding: 4px 16px;
}
/*responsiveness in tablet mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .sub_head_content_table {
    width: 82vw;
    overflow-y: auto;
    overflow-x: hidden;
    height: 67vh;
  }
}

/*****media querry for laptop mode ****/
@media screen and (min-width: 1024px) and (max-width: 1439px) and (min-height: 600px) {
  .sub_head_content_table {
    width: 82vw;
    overflow-y: auto;
    overflow-x: hidden;
    height: 60vh;
  }
}

/**** destop *****/
@media screen and (min-width: 1440px) {
  .sub_head_content_table {
    width: 82vw;
    overflow-y: auto;
    overflow-x: hidden;
    height: 65vh;
  }
}
.lodLevel {
  display: block;
  width: 260px;
  white-space: normal;
  overflow-wrap: break-word;
}
.lodTag {
  display: block;
  width: 580px;
  white-space: normal;
  overflow-wrap: break-word;
}
.CategoryName,
.identfierName,
.questionbankName {
  display: block;
  width: 150px;
  white-space: normal;
  overflow-wrap: break-word;
}
.CategoryDescription,
.identfierDescription,
.questionbankDescription {
  display: block;
  width: 270px;
  white-space: normal;
  overflow-wrap: break-word;
}
.CategoryTag,
.identfierTag,
.questionbankTag {
  display: block;
  width: 350px;
  white-space: normal;
  overflow-wrap: break-word;
}
.subjectWrap {
  display: block;
  width: 70px;
  white-space: normal;
  overflow-wrap: break-word;
}
.subjectArea {
  display: block;
  width: 70px;
  white-space: normal;
  overflow-wrap: break-word;
}
.subjecttopic {
  display: block;
  width: 70px;
  white-space: normal;
  overflow-wrap: break-word;
}
.subjectSubtopic {
  display: block;
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
}
.subjectTag {
  display: block;
  width: 240px;
  height: auto;
  overflow-wrap: break-word;
}
