/* Modal backdrop */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1002;
}
.modelBaseFilter{
  
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0px 0px 15px 0px;
    border-radius: 16px;
    width: 550px;
    height: 250px;
    box-shadow: 0px 8px 24px 0px #7090B01A !important;
   

}
.modelBaseFilterContents{
  
  height: 72px;  
  width: 91%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 15px 0px 0px 20px;
}
.previewBackdrop{
  background-color: transparent;
  width: auto;
}
/* Show modal when open */
.modalBackdrop.show {
  display: flex;
}
.previewBackdrop .polyImg{
  width: 20px;
  height: 20px;
  position: absolute;
  top: -12px;
  right: 15px;
}
/* Modal container */
.modalContainer {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center modal */
  background: white;
  padding: 0px 25px 15px 25px;
  border-radius: 16px;
  width: 500px;
  height: 250px;
  box-shadow: 0px 8px 24px 0px #7090B01A !important;
  display: none;
}
.previewBackdrop .modalContainer{
  left: 70%;
  top: 41%;
  height: 180px;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
}

/* Show modal when open */
.modalContainer.show {
  display: block;
}

/* Modal header */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  box-shadow: 0px 8px 24px 0px #3881ca1a;
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
}

.modalClose {
  cursor: pointer;
  background: none;
  border: none;
  height: 25px;
}

/* Modal content */
.modalContent {
  height: 72px;
  margin-top: 10px;
  width: 100%;
  font-family: IBM Plex Sans ,sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Modal select */
.modalSelect {
  width: 100%;
  height: 50px;
  padding: 8px;
  margin-top: 10px;
  border-radius: 12px;
  border: 1.5px solid #ECF3F5;
  color: #272727;
  font-family: IBM Plex Sans ,sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

/* Modal buttons */
.modalButtons {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  height: 56px;
}
.modalBaseFilterButtons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  height: 56px;
}
.modalCancelBtn,
.modalDuplicateBtn {
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modalCancelBtn {
  background-color: transparent;
  color: #272727;
  text-decoration: underline;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-family: IBM Plex Sans ,sans-serif;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.modalDuplicateBtn {
  background-color: #00ABFB;
  color: #FEFEFE;
  border-radius: 16px;
  padding: 15px;
  margin-left: 20px;
  width: 196px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  box-shadow: 0px 16px 40px 0px #7090B033;
}

/* Hover effect for the button */
.modalDuplicateBtn:hover {
  background-color: #0056b3;
}

/* Disabled button styles */
.modalDuplicateBtn:disabled {
  background-color: #c6c6c6;
  /* Gray background */
  color: #808080;
  /* Gray text */
  cursor: not-allowed;
  /* Prevent pointer cursor */
  border: 1px solid #dcdcdc;
  /* Light gray border */
}

.errorText {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.grouped-question {
  margin-bottom: 15px;
}

.question-id {
  margin-top: 5px;
}