/* _________________ SIDE BAR CSS ___________________________  */
#side_bar .side_bar_contents {
  overflow-x: hidden;
  height: 100%;
}

#side_bar {
  position: fixed;
  height: 100vh;
  width: 29vh;
  background: #ffffff;
  box-shadow: 0 4px 51px rgba(0, 0, 0, .14);
  top: 0;
  left: 0;
  transition: all 0.5s;
}

#side_bar.hide {
  left: -100%;
}

#side_bar .side_bar_contents .menu_top_area {
  height: 18%;
  justify-content: center;
  display: flex;
  align-items: center;
}

#side_bar .side_bar_contents .menu_bottom_area {
  height: 10%;
  display: flex;
  align-items: flex-end;
}

#side_bar .side_bar_contents .menu_bottom_area .menu_bottom_area_sub {
  height: 80px;
  width: 172px;
  align-items: center;
  display: flex;
}

#side_bar .brand_logo {
  margin-bottom: 30px;
}

#side_bar .user_profile .user_area .user_info .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#side_bar .user_profile .user_area .user_info .details {
  margin-left: 10px;
}

#side_bar .user_profile .user_area .user_info .details span.user_name {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Inter-SB', sans-serif;
  color: #ffffff;
  margin-bottom: 4px;
  display: block;
  text-transform: capitalize;
}

#side_bar .user_profile .user_area .user_info .details span.user_type {
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  display: block;
  text-transform: capitalize;
}

#side_bar .user_profile .user_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#side_bar .user_profile .user_area .user_info {
  display: flex;
  align-items: center;
}

#side_bar .user_profile:hover a svg path {
  fill: #3b82f6;
}

#side_bar .user_profile a svg path {
  fill: #bbbbbc;
}

#side_bar .menu_title {
  padding: 50px 30px 0;
  margin-bottom: 22px;
}

#side_bar .menu_title span.title_menu {
  display: block;
  text-transform: uppercase;
  color: #bbbbbc;
  font-family: 'Inter-SB', sans-serif;
  font-size: 14px;
  line-height: 21px;
}

#side_bar ul.main_menus {
  padding: 0 0px;
}

#side_bar .menus {
  padding: 0px 0px 0;
  overflow-y: auto;
  height: 72%;
  position: relative;
  overflow-x: hidden;
}

#side_bar ul.main_menus li .menu_item_content {
  display: flex;
  padding: 10px;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 5px;
}

#side_bar ul.main_menus li:last-child {
  margin-bottom: 0;
  margin-top: 0px;
}

#side_bar ul.main_menus_top {
  height: calc(100vh - 227px);
  overflow: auto;
}

#side_bar ul.main_menus_top::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

#side_bar ul.main_menus_top .accordian-custom-button {
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  padding: 0;
}

#side_bar ul.main_menus_top .accordian-custom-button svg {
  margin-right: 20px;
}

#side_bar ul.main_menus li .menu_item {
  display: flex;
  align-items: center;
}

#side_bar ul.main_menus li .menu_item svg {
  margin-right: 8px;
}

/* #side_bar ul.main_menus li:hover svg path, */
#side_bar ul.main_menus li:hover .menu_item span.menu_name {
  fill: none;
  color: var(--e-global-color-blue);
}

#side_bar ul.main_menus li a.active .menu_item_content .menu_item span.menu_name .sub_menu_item {
  fill: none;
  color: var(--e-global-color-blue);
}

#side_bar ul.main_menus li .menu_item_content.active {
  border-right: 3px solid #00abfb;
}

#side_bar ul.main_menus li .active .menu_item span.menu_name {
  color: var(--e-global-color-blue) !important;
}

#side_bar ul.main_menus li .menu_item span.menu_name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  transition: 0.3s ease;
  text-transform: capitalize;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu {
  margin-bottom: 30px;
  margin-left: 27px;
  position: relative;
  width: 330px;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li {
  margin: 0 !important;
  padding: 14px 40px;
  transition: 0.3s ease;
  position: relative;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li a {
  display: flex;
  align-items: center;
  color: #bbbbbc;
  transition: 0.3s ease;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li a svg {
  margin-right: 11px;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li a svg path {
  fill: #bbbbbc !important;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li a:hover svg path {
  fill: #3b82f6 !important;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li a:hover {
  color: #3b82f6;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li.submenu_actv a {
  color: #3b82f6 !important;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li.submenu_actv a svg path {
  fill: #3b82f6 !important;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  width: 36px;
  background: #f1f2f3;
  transform: translate(0, -50%);
  z-index: -1;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li.submenu_actv {
  background: #eff6ff;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li:hover {
  background: #eff6ff;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #f1f2f3;
}

#side_bar ul.main_menus li .sub_menu_items ul.sub_menu li a span.submenu_name {
  text-transform: capitalize;
}

/* _________________ SIDE BAR CSS ___________________________  */

.Submenu_sidebar {
  padding-left: 26%;
  margin-top: -7px;
}

.submenu_span {
  font-size: 12px !important;
  margin-bottom: 8px;
  font-weight: 400 !important;
}

/*CSS new menues under question bank menu */
.sub_menu {
  padding-left: 26%;
  display: block;
  margin-top: -10px;
  margin-bottom: 5px;
}

.sub_menu_item {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  color: #272727 !important;
  width: 116px;
  height: 18px;
  top: 32px;
  left: 32px;
  padding: 0px 29px 0px 0px;
  gap: 0px;
  text-decoration: none;
}

.menu_item_content.active .menu_item {
  font-weight: bold;
  color: #00abfb !important;
}

.sub_menu_item.active {
  color: #00abfb !important;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.signOutButton{
  background: none;
  border: none;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  padding: 0px;
}
/*responsiveness in tablet mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #side_bar {
    width: 15vw;
  }
}

@media (min-width: 1024px) and (max-width: 1920px) {
  /* Styles for laptops */
  #side_bar {
    width: 13vw;
  }
}

/*Media Query for Laptop - 11dec2024 */
@media (min-width: 1024px) and (max-width: 1920px) {
  /* Styles for laptops */
  body {
    font-size: 1rem; 
  }
}

