.question_bank_table_top {
  padding: 30px 30px;
}

.question_bank {
  width: 100%;

}

.pointer {
  cursor: pointer;
}

.question_bank .table_content_report {
  background-color: #ffffff;
  height: 83vh;
  overflow-y: scroll;
  border-radius: 12px;
}

.table_content_report .top_content_table {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table_title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 30%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
}

.table_content_report .top_content_table .user_management_search_listing {
  margin: 10px 30px;
}

.table_content_report .top_content_table .search_listing {
  width: 20%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);

  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: left;
  align-items: start;
  padding-left: 5px;
  max-width: 100px;
  width: 30px;
}

.search_listing .input_search {
  height: 100%;
  display: flex;
  width: 80%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: 10px;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.table_content_report .top_content_table .add_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 40px;
  margin-left: 27em;
  gap: 50px;
  font-size: 14px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 100%;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 13px;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.user_management_table_body_content {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.activate {
  background-color: #e9fcf1;
  color: #26ab5b;
  width: 62px;
  height: 20px;
  border: 0.3px solid #26ab5b;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.inactive {
  background-color: #f6f7fb;
  color: #8e9eab;
  width: 62px;
  height: 20px;
  border: 0.3px solid #8e9eab;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
  float: right;
}

.action {
  margin-right: 26px;
  position: relative;
}

.top_section {
  display: block;
}

.top_section .left {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
}

.top_section .right {
  display: inline-flex;
  float: right;
  align-items: center;
  color: #00abfb;
  font-size: 16px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
}

.profile_detail_div .status {
  float: right;
  background-color: #e9fcf1;
  border: 1px solid #26ab5b;
  color: #26ab5b;
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  padding: 5px 20px;
}

.profile_detail_div {
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 26px;
  padding: 20px 40px 10px 30px;
}

.profile_title_div {
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 26px;
  margin-left: 30px;
}

.profile_status {
  padding: 10px 30px;
}

.organisation_table {
  width: 70%;
}

.organisation_detail_head {
  height: auto !important;
}

.oragnisation_status {
  display: flex;
}

.status_Organisation {
  display: inline-block;
  float: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.tbody_question_bank {
  display: flex;
  width: 100%;
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid #ecf3f5;
}

.tbody_question_bank div {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.tbody_question_bank .first {
  text-align: left;
}

.tbody_question_bank .last {
  border-left: 1px solid #ecf3f5;
}

.tbody_question_bank .second {
  border-left: 1px solid #ecf3f5;
}

.profile_detail_info {
  padding: 20px;
}

.title_detail {
  font-size: 14px;
  font-weight: 600;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
}

.description_detail {
  font-size: 12px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
}

.profile_detail_info .second,
.profile_detail_info .third,
.profile_detail_info .fouth,
.profile_detail_info .last {
  text-align: left;
}

/* Start of Create organization CSS  */
.formContainer {
  padding: 20px;
  width: 90%;
  height: 72vh;
  margin-left: 30px;
  border-radius: 18px;
  background-color: #fff;
  box-shadow: 0px 8px 24px 0px #7090B026;
}

.form_title {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #272727;
  line-height: 26px;
  margin-left: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 45px 0 0 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup input {
  border: 1px solid #ECF3F5;
  border-radius: 12px;
  height: 30px;
  padding: 10px;
}
 
.mainForm {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.name_label {
  margin-bottom: 8px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #272727;
}

.name_input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.name_input_first {
 width: 50%;
 height: 30px;
 box-shadow: 0px 0px 5px rgb(203, 201, 201);
}

.radio_group {
  display: flex;
  gap: 20px;
  margin-top: 7px;
}

.radio_label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

/* Create the custom radio button */ 
.radio_checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid #00abfb;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

/* Change the appearance when the radio button is checked */
input[type='radio']:checked + .radio_checkmark {
  border-color: #00abfb;
}

/* Add a circle inside the radio button when checked */
input[type='radio']:checked + .radio_checkmark::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00abfb;
  position: absolute;
  top: 4px;
  left: 4px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-start;
}

.cancelButton {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  display: flex;
  align-items: center !important;
  justify-content: center;
  text-decoration: underline;
  padding: 15px 20px 15px 0px;
}

.addButton {
  width: 22%;
  height: 50px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background-color: #00abfb;
  color: white;
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 16px 40px 0px #7090b033;
}

.confirmButton {
  height: 50px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background-color: #00abfb;
  color: white;
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 16px 40px 0px #7090b033;
}

.org_back_button {
  cursor: pointer;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 15px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}  

.required_name {
  color: #ff5757 !important;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000ba;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  width: 35%;
  background-color: white;
  height: 50%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.modalTwoButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.input_user, .input_limit {
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
}

.modalHeader {
  text-align: right;
  width: 100%;
  padding-top: 10px;
}

.modalClose {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modalBody {
  width: 75%;
}

.input_disabled {
  background-color: #f0f0f0;
  color: #a0a0a0;
  cursor: not-allowed !important;
  border: 1px solid #d3d3d3;
  opacity: 0.1;
  pointer-events: auto;
}

/* End of Create organization CSS  */

/******   organization profile css   ******/
.orgTop_section  { 
  margin: 25px 30px;
  height: 80vh;
  width: 82vw;
}
.organisation_profileTableHeight {
  max-height: 72vh;
  min-height: 40vh;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 24px 0 #7090b026;
  margin-top: 2%;
  overflow-y: auto;
  width: 100%;
}
.organisation_profileTableHeight::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.organisation_profileTableHeight::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.organisation_profileTableHeight::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.buttonStyle {
  background: none;
  border: none;
  padding: 4px 0px !important;
}
.profile_headerdetail {
  display: flex;
  justify-content: space-between;
}
.organisationInfo {
  display: flex;
  gap: 16px;
}

.editButtonStyle {
  width: 90px;
  border: 1.5px solid #00abfb;
  background: none;
  border-radius: 10px;
  padding: 5px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backButton {
  position: relative;
  bottom: 6px;
  text-decoration: underline;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.editButton {
  color: #00abfb;
}
.statusActive {
  background-color: #E9FCF1;
  border: 0.3px solid #26AB5B;
  border-radius: 4px;
  color: #26AB5B;
  font-size: 10px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  height: 15px;
  line-height: 13px;
  padding: 4px;
  text-align: center;
  width: 46px;
}
.statusInactive {
  background-color: #f6f7fb;
  border: .3px solid #8e9eab;
  border-radius: 4px;
  color: #8e9eab;
  font-size: 10px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  height: 16px;
  line-height: 14px;
  padding: 4px 8px;
  text-align: center;
  width: 45px;
}
.orgProfile_detail_info {
  border: 1px solid #E2ECF080;
  padding: 20px;
  margin: 20px 24px 25px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 #0000000f;
}
.orgAdmin {
  display: flex;
  flex-direction: column;
  width: 530px;
  height: auto;
}
.orgLabel {
  font-size: 14px;
  font-weight: 600;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 24px;
  padding: 10px;

}
.OrganizationAdminWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.OrganizationAdminName {
  background-color: #F4F5F6;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 400;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
  margin: 4px;
  border-radius: 20px;
  color: #212529;
  overflow-wrap: break-word;
  white-space: normal;
  width: auto;
  max-width: 515px;
}
.vertical_line {
  background-color: #c7ccd1;
  display: inline-block;
  height: 80px;
  margin-left: 30px;
  margin-right: 15px;
  width: 1px;
}
.orgUserCount, .orgUserQuestionCount {
  font-size: 14px;
  font-weight: 400;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
  padding: 10px;
}