.previewModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000ba;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview_center_div {
  background: white;
  border-radius: 18px;
  width: 80%;
  max-width: 1200px;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}

.preview_header {
  height: 124px;
  padding: 24px 16px;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0px 8px 24px 0px #7090b01a;
}

.question_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.info_row {
  display: flex;
  align-items: center;
}

.label {
  color: #272727;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.value {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.separator {
  margin: 0 24px;
  border: 1px solid #c7ccd1;
}

.tags_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subjectTags {
  display: flex;
  align-items: center;
  width: 40%;
  height: 10vh;
  padding: 7px;
  border-radius: 50px;
  color: #212529;
  font-size: 12px;
  line-height: 20px;
  background: #e9f8ff;
}

.subjectTags span{
  margin: 10px;
}

.last_activity {
  color: #8e9eab;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  right: 16px;
}
.header_actions {
  display: flex;
}
.edit_actions {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.edit_button {
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  align-items: center;
  background-color: #00abfb;
  border: none;
  border-radius: 5px;
  display: flex;
  height: 42px;
  justify-content: center;
  width: 180px;
  cursor: pointer;
}
.edit_button span {
  margin-left: 10px;
}

.duplicateBtnWrap {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 10px;
  border: 1px solid #00abfb;
  cursor: pointer;
  background: transparent;
  padding: 0;
}
.duplicateBtn {
  background-image: url('../../assets/images/duplicateBtn.svg');
  width: 40px;
  height: 40px;
  display: flex;
  background-repeat: no-repeat;
  background-position: center center;
}

.close_button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.preview_content {
  flex: 1;
  min-height: 0;
  padding: 24px;
}

.preview_iframe {
  width: 100%;
  height: 100%;
  min-height: 50vh;
  border: none;
}

.statusDropdown {
  position: relative;
}

.dropdownButton {
  margin-left: 20px;
  color: #cca400;
  display: flex;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 12px;
  width: 110px;
  height: 32px;
  background: #fff9e8;
  border: 0.3px solid #ffcf00;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.arrow {
  margin-left: 10px;
  display: block;
  background-image: url('../../assets/images/uparrowBtn.svg');
  width: 20px;
  height: 14px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: transform 0.3s ease; /* Smooth transition for rotation */
}
.arrow.rotated {
  transform: rotate(180deg);
}

.dropdownContent {
  position: absolute;
  margin-top: 10px;
  box-shadow: 0px 8px 24px 0px #7090b026;
  background: #ffffff;
  border-radius: 12px;
  padding: 10px;
  z-index: 1;
  font-size: 12px;
  min-width: 92px;
  height: 65px;
  margin-left: 20px;
  line-height: 12px;
}

.dropdownContent label {
  display: flex;
  margin-bottom: 11px;
  cursor: pointer;
  align-items: center;
}

.dropdownContent label input[type='radio'] {
  margin: 0;
  margin-right: 8px;
}
