.filterModal {
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterModal .center_div {
  width: 95%;
  background-color: white;
  margin-top: 2%;
  border-radius: 30px;
}

.filterModal .center_div_review {
  width: 70%;
  background-color: white;
  height: 85%;
  border-radius: 30px;
}

.filterModal .center_div .header_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(241, 236, 236);
  -webkit-box-shadow: 0 8px 6px -6px rgb(230, 216, 216);
  -moz-box-shadow: 0 8px 6px -6px rgb(223, 203, 203);
  box-shadow: 0 8px 6px -6px rgb(220, 211, 211);
}

.filterModal .center_div .header_top .title {
  padding: 2.2% 3%;
  width: 50%;
}

.filterModal .center_div .header_top .title div {
  display: flex;
  align-items: center;
}

.filterModal .center_div .header_top .title div span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.filterModal .center_div .header_top .close {
  width: 50%;
  padding-right: 0.5%;
}

.filterModal .center_div .header_top .close button {
  float: right;
  margin-right: 5%;
  background-color: white;
  border: none;
}

.filterModal .center_div .bottom_fields {
  padding: 3%;
  height: 75%;
}

.filterModal .center_div .bottom_fields .tags {
  width: 65%;
}

.filterModal .center_div .bottom_fields .drop {
  width: 100%;
  display: flex;
  gap: 20px;
}

.filterModal .center_div .bottom_fields .drop .drop_item {
  width: 28%;
}

.filterModal .center_div .bottom_fields .drop .tags {
  width: 65%;
}

.filterModal .center_div .bottom_fields .tags .subject {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 5px;
}

.filterModal .center_div .bottom_fields .drop .drop-item .subject {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-left: 5px;
}

.header_drop_filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bottom_fields .blue_button {
  background-color: #00abfb;
  color: white;
  width: 35%;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  margin-top: 4%;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.list_input_primary {
  position: absolute;
  top: 55px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  z-index: 50;
}

.list_input_primary div {
  padding: 8px 5px;
}

.list_input_primary div:hover span {
  background-color: #00abfb;
  color: white;
}

.list_input_primary div span {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.search_listing .cancel_button {
  float: right;
}

.search_listing .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.filterModal .center_div .top_title {
  width: 100%;
    align-items: center;
    justify-content: center; 
}

.filterModal .center_div .top_title .title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filterModal .center_div .top_title .title div {
  display: flex;
  align-items: center;
}

.filterModal .center_div .top_title .title div span {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.filterModal .center_div .top_title .close {
  width: 100%;
}

.filterModal .center_div .top_title .close button {
  float: right;
  margin-right: 2%;
  margin-top: 2%;
  background-color: white;
  border: none;
}

.master_modal .tags .subject p {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.master_modal .tags .subject {
  width: 100%;
}

.master_modal .tags .subject input {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 20px;
  font-size: 12px;
}

.master_modal .tags {
  display: flex;
  gap: 20px;
}

.master_modal {
  padding: 0px 50px;
  margin-top: 20px;
}

.description_input {
  height: 100px !important;
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 90.5%;
  border-radius: 10px;
  border: none;
  padding: 20px;
  font-size: 12px;
}

.saveButton_questionBank {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.saveButton_questionBank .cancel {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.margin_top_20 {
  margin-top: 20px;
}

.blue_button {
  background-color: #00abfb;
  color: white;
  width: 197px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  cursor: pointer;
}

.filterModal .center_div_large {
  width: 65%;
  background-color: white;
  height: 85%;
  border-radius: 30px;
}

.filterModal .center_div_large .top_title {
  width: 100%;
}

.filterModal .center_div_large .top_title .title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filterModal .center_div_large .top_title .title div {
  display: flex;
  align-items: center;
}

.filterModal .center_div_large .top_title .title div span {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.filterModal .center_div_large .top_title .close {
  width: 100%;
}

.filterModal .center_div_large .top_title .close button {
  float: right;
  margin-right: 2%;
  margin-top: 2%;
  background-color: white;
  border: none;
}

.identifier_master_modal {
  display: flex;
  justify-content: center;
  width: 100%;
}

.identifier_master_modal .master_modal {
  width: 50%;
}

.identifier_save {
  float: right;
  padding: 5%;
}

.master_tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0px solid #ffffff;
  box-shadow: 0px 0px 10px rgb(226, 218, 218);
  max-width: 574px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.master_tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.master_tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.master_tag_search .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  padding-left: 20px;
  width: 100%;
  position: relative;
}

.master_tag_search .input_search input,
.master_tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.master_tag_search .cancel_button {
  float: right;
}

.master_tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slugs_Tag {
  width: 90px;
  height: 28px;
  border-radius: 50px;
  background-color: #e9f8ff;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  padding: 5px 20px;
}

/**** DELETE MODAL***/

.delete {
  height: 40% !important;
}

.title_delete {
  font-size: 24px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  padding: 10%;
}

.center {
  justify-content: center !important;
  width: 100%;
}

/*** MEDIUM MODAL **/

.mediumModal {
  position: fixed;
  height: 100vh;
  background: #000000ba;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mediumModal .center_div {
  width: 45%;
  background-color: white;
  height: 380px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mediumModal .center_div h3 {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 0px 120px;
  margin-bottom: 15px;
}

.mediumModal .center_div p {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 0px 80px;
  margin-top: 0px;
}

.saveButton_questionCreation_Modal {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
  bottom: 20px;
  z-index: 50;
  margin-top: 20px;
}

.saveButton_questionCreation_Modal .cancel {
  text-decoration: underline;

  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.underline_active {
  border-bottom: 5px solid #00abfb;
}

.closeModalreviewDetail {
  background: white;
  border: none;
  position: absolute;
  right: 14px;
}

/*** REVIEW MODAL **/
.review_page .user_question {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  color: black;
}

.review_page .user_question .question_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  height: 10vh;
  position: relative;
}

.review_page .user_question .question_header .question_id {
  margin-left: 10px;
}

.review_page .user_question .question_header .bw_line {
  border: 1px solid #c7ccd1;
  height: 3vh;
}

.review_page .user_question .user_info {
  display: flex;
  align-items: center;
  font-size: x-small;
  height: 10vh;
  box-shadow: 0px 10px 10px 0px #7090b026;
}

.review_page .user_question .user_info .user_topics {
  display: flex;
  align-items: center;
  background-color: #e9f8ff;
  margin-left: 2em;
  height: 5vh;
  width: 70%;
  border-radius: 50px;
  text-align: center;
  justify-content: center;
}

.review_page .user_question .user_info .rest {
  width: 65%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.review_page .user_question .user_info .rest .user_activity {
  margin-right: 2em;
  color: #8e9eab;
}

.review_page .user_question .user_info .rest .edit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: small;
  color: #fff;
  margin-right: 1em;
  height: 6vh;
  width: 40%;
  border-radius: 5px;
  background-color: #00abfb;
  border: none;
  cursor: pointer;
}

.review_page .user_question .main_content {
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review_page .user_question .main_content .container {
  height: 50vh;
  width: 95%;
  margin-top: 1em;
  border-top: 1px solid #c7ccd1;
  border-bottom: 1px solid #c7ccd1;
  display: flex;
}

.review_page .user_question .main_content .container .section_one {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  padding: 10px;
  border-right: 1px solid #c7ccd1;
}

.review_page .user_question .main_content .container .section_one img {
  width: 100%;
}

.review_page .user_question .main_content .container .section_two {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px;
}

.review_page .user_question .main_content .container .section_two img {
  width: 100%;
}
