/* General modal styling */
.filterModal {
  font-family: 'IBM Plex Sans', sans-serif;
  position: fixed;
  height: 88vh;
  display: flex;
  border: #272727;
  padding: 0px 30px;
  overflow-y: scroll;
}
.filterModal::-webkit-scrollbar {
  width: 10px; /* Set the width of the vertical scrollbar */
  height: 10px; /* Set the height of the horizontal scrollbar */
}
.filterModal::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.filterModal::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.full_container {
  width: 79vw;
  background: white;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 10px #e7e2e2;
  padding: 15px 15px 15px 18px;
}

.center_div_large {
  width: 78%;
  border: none;
  background: 'white';
  border-radius: '15px';
  box-shadow: '0px 5px 10px rgb(231, 226, 226)';
}

.identifier_master_modal {
  display: flex;
  justify-content: center;
  flex-wrap: no-wrap;
  gap: 15px;
  width: 100%;
  border: none;
}

.descriptionBox {
  padding-top: '150px';
}

.back_button {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 2%;
  margin-left: -10px;
  margin-top: 1%;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.arrow {
  font-size: 14px;
  margin-right: 8px;
  margin-top: 3px;
}

.identifier_master_modal .master_modal {
  width: 50%;
  margin-top: 20px;
}

.master_modal .tags .subject p {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 10px;
}

.master_modal .tags .subject {
  display: flex;
  width: 100%;
}

.master_modal .tags .subject input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 20px;
  font-size: 12px;
}

.master_modal .tags {
  display: flex;
  gap: 20px;
}

.top_title {
  width: 100%;
  justify-content: start;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.master_modal {
  flex: 1;
}

.tags {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subject {
  font-weight: 500;
}

.identifier_master_modal .master_modal .tags.subject {
  display: flex;
  align-items: center;
  gap: 10px;
}

.description_input {
  height: 100px !important;
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 90.5%;
  border-radius: 10px;
  border: none;
  padding: 20px;
  font-size: 12px;
}

.margin_top_20 {
  margin-top: 20px;
}

.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.badge {
  border-radius: 50px;
  background-color: #e8f8ff;
  border: none;
  font-size: 14px;
  line-height: 30px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  padding: 10px 25px;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-all;
  display: inline-flex;
  max-width: 930px;
  width: auto;
  height: auto;
}

.master_tag_search .cancel_button {
  position: initial;
  float: right;
}

.input_search input {
  width: 100%;
  padding: 8px; 
  border: none; 
  outline: none;
  border-radius: 10px; 
  background-color: transparent;
  font-size: 4px;
  color: #333;
}

.list_input_primary:hover {
  background-color: #fff;
}

.list_input_primary button {
  padding: 10px 5px;  
  display: inline-flex;
  align-items: center;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  background: none;
  border: none;
}

.list_input_primary button:hover span {
  background-color: #00abfb;
  color: white;
}

.list_input_primary button span {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 7px 22px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}

.pointer {
  font-weight: 500;
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pointer:hover {
  background-color: #fff;
}

.cancel_button button {
  background: none;
  border: none;
  cursor: pointer;
}

.blue_button {
  padding: 10px 20px;
  background-color: rgb(20, 181, 245);
  color: white; 
  border: none;
  border-radius: 15px; 
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.saveButton_questionBank {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cancel {
  color: black;
  border: none;
  cursor: pointer;
  font-size: 16px;
  background: none;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 0px;
  transition: background-color 0.3s ease;
  text-decoration: underline;
}

.input_search input:focus {
  outline: none;
  border-color: #007bff;
}

.input_search span {
  padding: 5px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.tags p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

/* Container for the search input */
.master_tag_search {
  position: relative;
  margin-bottom: 26px;
}

/* Styling for the input search field */
.input_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 2px solid #f0f6f7;
  border-radius: 10px;
  padding: 3px 5px;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
}



.input_search input::placeholder {
  color: #aaa;
}

/* The dropdown list that appears when searching */
.list_input_primary {
  position: absolute;
  top: 100%; 
  left: 0; 
  width: 100%;
  background-color: white;
  border: 1px solid #ddd; 
  border-radius: 8px; 
  max-height: 200px; 
  overflow-y: auto; 
  padding: 10px;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  z-index: 10; 
}

.selected_tags_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected_tag_item {
  background-color: #f5f6fa;
  padding: 12px 12px;
  border-radius: 20px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
}

.remove_icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  fill: #272727;
  transition: transform 0.2s;
}

.remove_icon:hover {
  transform: scale(1.2);
}

.AddpipelineWaterMark {
  font-weight: 300;
  color: #8e9eab;
  font-size: 14px;
}

.master_tag_search .input_search input,
.master_tag_search .input_search input:focus-visible {
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.master_tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validationError {
  color: red;
  font-size: 12px;
  margin-top: -15px;
}
.validationErrortag {
  color: red;
  font-size: 12px;
  margin-top: 0px;
}

/* Title CSS */
.add_title {
  margin: 0;
}
.addtitle_tag {
  margin: 0;
  margin: 28px 0 0;
  padding-top: 0;
  font-weight: bold;
}
.required_field {
  padding-left: 3.5px;
  color: red;
}
.subtopicDescripTextarea {
  border: none;
  background-color: #ffffff;
  outline: none;
  width: 100%;
  height: 85%;
  font-family: 'IBM Plex Sans', sans-serif;
  resize: none;
}

/* Only show scrollbar when content overflows */
.subtopicDescripTextarea:focus {
  overflow-y: auto;
}

.subtopicDescripTextarea::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.subtopicDescripTextarea::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.subtopicDescripTextarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}