@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/*********************************************************************************** USER SIGN IN PAGE START FROM HERE **********************************************************************/

/********************************* BELOW PART WILL BE COMMON IN ALL COMPONENT PAGES CSS *****************************************/

body {
  margin: 0;
}

/***SECTION ONE ***/

.signup_page .section1 .ims_logo {
  display: block;
  margin: 1em 0em 0em 3em;
}

.signup_page .section1 .ims_logo .logo {
  height: 120px;
  width: 120px;
}

.signup_page .section1 .main_area {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signup_page .section1 .text {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  width: 40%;
  height: 14vh;
  border-radius: 15px;
  font-size: small;
  margin-left: 15%;
  line-height: 2em;
}

.signup-page .section1 .text_area1 {
  margin-left: 10%;
  margin-top: 2%;
  padding-top: 1%;
  position: relative;
}

.signup-page .section1 .text_area2 {
  position: relative;
  margin-left: 33%;
  margin-top: -13.5%;
}

.signup_page .section1 .public {
  display: flex;
  justify-content: end;
  height: 30vh;
  margin-top: -6%;
}
.signup_page .section1 .public .public_img {
  height: 280px;
  width: 250px;
}

.signup_page .section1 .public .one {
  margin-right: 20%;
  margin-top: -10%;
}

.signup_page .section1 .public .two {
  margin-top: -10%;
  margin-right: 58%;
}

/****** Section 2 ******/

.signup-page .section2 {
  background-color: #fff;
  width: 50%;
  float: left;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signup-page .section2 .nav-section {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 2%;
  margin-right: 10%;
}

.signup-page .section2 .nav-section a {
  text-decoration: none;
}

.signup-page .section2 .nav-section .nav-text {
  color: #454545;
  font-size: small;
  text-decoration: none;
  padding: 5px;
}

.signup-page .section2 .nav-section .nav-text:hover {
  color: black;
  font-weight: bold;
}

.signup-page .section2 .nav-section .fa-solid {
  color: lightgray;
  padding: 5px;
}

/*** USER SIGN IN AREA ***/

.signup-page .section2 .signup-form {
  height: 100%;
  display: flex;
  justify-content: center;
}

.signup-page .section2 .signup-form .user-form {
  background-color: ghostwhite;
  width: 70%;
  height: 87vh;
  margin-top: 3%;
  border-radius: 15px;
}
/**************************************************************** UPTO THIS PART ALL PAGES COMPONENT CSS WILL BE SAME ****************************************/

/**************************************************************** NOW BELOW THIS PART , ALL COMPONENT CSS WILL BE DIFFERENT (#REMEINDER#) ***********************/
.signup-page .section2 .signup-form .user-form .text-signin {
  width: fit-content;
  margin: 3em 1.5em;
}

/**** USER EMAIL AND PASSWORD AREA ***/

.signup-page .section2 .signup-form .user-form .user-info {
  margin: 3em 2.5em;
}
.signup-page .section2 .signup-form .user-info .mail-and-pass label {
  line-height: 2.5em;
  font-size: small;
}

.signup-page .section2 .signup-form .user-info .mail-and-pass label::after {
  content: ' *';
  color: red;
}

.signup-page .section2 .user-info .mail-and-pass input {
  width: 85%;
  height: 5vh;
  display: flex;
  padding-left: 2em;
  border-radius: 12px;
  border: 1px solid lightgray;
}

.signup-page .section2 .signup-form .user-info .mail-and-pass input:focus {
  border: 1px solid lightgrey;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) 0.1;
}

/**** FOREGT PASSWORD AREA AND OTHER OPTIONS ***/
.signup-page .section2 .signup-form .user-form .user-options {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: small;
  line-height: 3em;
}

.signup-page .section2 .signup-form .user-form .user-options .forget-pass {
  margin-left: 37%;
}

.signup-page .section2 .user-info input {
  width: 5%;
  height: 2.5vh;
}

/**** SIGN UP BUTTON AREA ***/
.signup-page .section2 .signup-form .signin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
}

.signup-page .section2 .signup-form .signin-button .bt-signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}
/************************************************************** USER  SIGN IN PAGE ENDS HERE **************************************************************************/

/****************************************** FORGET PASSWORD PAGE AFTER CLICKING ON FORGOT PASSWORD LINK IN SIGN IN PAGE STARTS FROM HERE *******************************************/
.signup-page .section2 .signup-form .user-form .forget-password {
  background-color: red;
  width: fit-content;
  margin: 3em 1.5em;
}

.signup-page .section2 .signup-form .user-form .forget-password .some-text {
  width: fit-content;
  font-size: small;
  font-weight: 400;
}

.signup-page .section2 .signup-form .user-form .user-pass-info {
  margin: 3em 2.5em;
  padding-top: 2.5em;
}

.signup-page .section2 .signup-form .user-form .user-pass-info .setting-new-pass label::after {
  content: ' *';
  color: red;
}

.signup-page .section2 .signup-form .user-form .user-pass-info .setting-new-pass input {
  width: 85%;
  height: 5vh;
  display: flex;
  padding-left: 2em;
  border-radius: 12px;
  border: 1px solid lightgray;
  margin-top: 3%;
}

.signup-page .section2 .signup-form .user-form .forget-pass-bt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 43%;
}

.signup-page .section2 .signup-form .user-form .forget-pass-bt-signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}
/********************************************* FORGET PASSWORD PAGE AFTER CLICKING ON FORGOT PASSWORD LINK IN SIGN IN PAGE ENDS HERE ************************************************/

/*********************************************** AFTER CLICKING ON EMAIL LINK -> SETTING NEW PASSOWRD PAGE APPEARS ******************************************************************/

/*************************************************************************** SETTING NEW PASSWORD PAGE STARTS FROM HERE *************************************************************/
.signup-page .section2 .signup-form .create-new-pass {
  width: fit-content;
  margin: 3em 1.5em;
}

.signup-page .section2 .signup-form .user-info {
  margin: 3em 2.5em;
}

.signup-page .section2 .signup-form .user-info .new-pass label {
  line-height: 3em;
  font-size: small;
}

.signup-page .section2 .signup-form .user-info .new-pass input {
  width: 85%;
  height: 5vh;
  display: flex;
  padding-left: 2em;
  border-radius: 12px;
  border: 1px solid lightgray;
  margin-top: 1%;
}

.signup-page .section2 .signup-form .setting-new-pass-signin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
}

.signup-page .section2 .signup-form .setting-new-pass-signin-button .bt-setting-new-pass-signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}

/************************************************************ BELOW CODE WILL BE DYNAMICALLY CALLED ******************************************/

/***** BELOW CODE WILL ACTIVE ONLY WHEN NEW PASSWORD BAR REQUIEMENTS WILL NOT BE FULLFILLED *********/
.signup-page .section2 .signup-form .new-pass .hidden-alert {
  display: none;
}

/**** THIS WILL BE ACTIVE ONLY WHEN MIN LENGTH CONDITION FULL FILLED ****/
.signup-page .section2 .signup-form .user-form .new-pass .hidden-alert .min-length {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN STRONG PASSWORD CONDITION FULL FILLED ****/
.signup-page .section2 .signup-form .user-form .new-pass .hidden-alert .strong-password {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN BOTH THE CONDITION FULL FILLED ****/
.signup-page .section2 .signup-form .user-form .new-pass .hidden-alert li {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN BOTH THE PASSWORDS MATCH ****/
.signup-page .section2 .signup-form .user-form .password-options {
  font-size: small;
  display: flex;
  align-items: center;
  line-height: 2em;
}

.signup-page .section2 .signup-form .user-form .password-options .hidden-password-matchbox {
  margin-left: auto;
  margin-right: 8%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #26ab5b;
  display: flex;
  align-items: center;
}

.signup-page .section2 .signup-form .user-form .password-options .hidden-password-matchbox .tick {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  background: linear-gradient(220.53deg, #9bf763 0%, #25ab5b 76.95%);
  border-radius: 50%;
  border: 1.5px solid #ffffff;
}

/********************************************** RESPONSIVE SM *************************/
@media only screen and (max-width: 768px) {
  .signup-page .section1 {
    transition: all 0.3s ease-in-out;
    display: none;
  }
  .signup-page .section2 {
    width: 100%;
    float: none;
    transition: all 0.4s ease;
  }

  .signup-page .section1 .text,
  .signup-page .section1 .public,
  .signup-page .section1 .public .public-img,
  .signup-page .section1 .public .one,
  .signup-page .section1 .public .two,
  .signup-page .section2 .signup-form .user-form {
    width: 100%;
    margin-left: 0;
    transition: all 0.4s ease;
  }
}
