.question_bank_table_top {
  margin-top: 2%;
  padding: 0px 30px 30px;
  height: 80vh;
}
.import_new_question {
  background-color: #00abfb;
  max-width: 214px;
  width: 75%;
  height: 45px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.import_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.import_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36%;
  height: 60px;
  position: relative;
  left: 25px;
}

.table_content_report {
  width: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  height: 83vh;
}

.table_content_report .top_content_table {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table_title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 30%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
}
.table_content_report .top_content_table .search_listing {
  width: 50%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 24px;
  background-color: #fff;
  border: none;
  border-radius: 14px;
  box-shadow: 0px 8px 24px 0px #7090b026;
  height: 40px;
  width: 50%;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 2px solid #ecf3f5;
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.search_listing .cancel_button {
  float: right;
}

.search_listing .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_listing .tag_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.search_listing .input_searchs {
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_searchs input,
.search_listing .input_searchs input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 0px;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}
.retryLink{
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding: 0px 0px 0px 8px;
  text-decoration: underline;
  border: none;
  background: none;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}
.sub_head_content_table {
  height: 58vh;
  overflow: scroll;
  
}
.sub_head_content_table::-webkit-scrollbar {
  width: 10px; 
  height: 6px;
}
.sub_head_content_table::-webkit-scrollbar-thumb {
  background-color:#DADCE0;
  border-radius: 4px;
}

.sub_head_content_table::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #272727;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 14px;
}
.table_content_report .sub_head_content_table table thead th {
  background-color: #e9f8ff;
  height: 41px;
  text-align: left;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: bold;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 24px;
  display: flex;
  width: 37px;
  align-items: center;
}

.table_content_report .sub_head_content_table table thead .first {
  display: revert;
  padding-left: 7px;
}

.table_content_report .sub_head_content_table table thead .second {
  display: revert;
  padding-left: 7px;
}

.table_content_report .sub_head_content_table table thead .third {
  display: revert;
  padding-left: 7px;
}

.table_content_report .sub_head_content_table table thead .fourth {
  display: revert;
  padding-left: 7px;
}

.table_content_report .sub_head_content_table table thead .fifth {
  display: revert;
  padding-left: 7px;
}
.table_content_report .sub_head_content_table table thead .sixth {
  display: revert;
  padding-left: 7px;
  cursor: auto;
}
.table_content_report .sub_head_content_table table thead .seventh {
  display: revert;
  padding-left: 7px;
}
.table_content_report .sub_head_content_table table thead .eighth {
  display: revert;
  padding-left: 7px;
}
.table_content_report .sub_head_content_table table thead .nineth {
  display: revert;
  padding-left: 7px;
  cursor: auto;
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.question_id_tag {
  display: inline-flex;
  margin-left: 13px;
  align-items: center;
}

.question_id_tag .id {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-decoration: underline;
  line-height: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  margin-right: 25px;
}

.question_id_tag .tag {
  text-align: center;
}

.question_id_tag .tag p {
  font-size: 12px;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 0px;
}

.question_id_tag .tag span {
  font-size: 10px;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  height: 14px;
  background-color: #e9f8ff;
  border-radius: 50px;
}

.general_button {
  width: 90px;
  height: 28px;
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}

.general_tag {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.author_tag {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}

.created_date,
.lod {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
}

.status {
  text-align: center;
}

.action {
  margin-right: 26px;
  position: relative;
}

.status span {
  background-color: #fff9e8;
  color: #ffcf00;
  width: 62px;
  height: 20px;
  border: 0.3px solid #ffcf00;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.underline_active {
  border-bottom: 5px solid #00abfb;
}
.statusSuccess {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 52px;
  border: 0.3px solid #26ab5b;
  background-color: #e9fcf1;
  font-weight: 500;
  color: #26ab5b;
  font-size: 10px;
  line-height: 12px;
  border-radius: 5px;
  padding: 5px;
}

.statusFailed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 52px;
  border: 0.3px solid #ff5757;
  color: #ff5757;
  background-color: #fff2f1;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  border-radius: 5px;
  padding: 5px;
}

.statusGenerating {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 52px;
  border: 0.3px solid #6f7e8d;
  color: #5f6368;
  background-color: #fcfcfc;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  border-radius: 5px;
  padding: 5px;
}
.action_button {
  box-shadow: 0px 4px 10px 5px #2125291a;
  position: absolute;
  right: 4px;
  top: 28px;
  height: 120px;
  width: 230px;
  padding: 10px 10px;
  border-radius: 18px;
  z-index: 60;
  background: white;
  font-weight: 400;
  line-height: 16px;
}
.downloadButton {
  font-size: 14px !important;
  padding: 7px !important;
  display: flex;
  gap: 7px;
  cursor: pointer;
  border: none;
  background: none;
}

/**** PAGINATION**/
.paginationWrap{
  width: 87vw;
  position: relative;
  bottom: -20px;
}

.pointer {
  cursor: pointer;
}
.selectedquestion {
  font-weight: 500 !important;
}
.back_button {
  border: none;
  padding: 15px 15px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
/*responsiveness in tablet mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .question_ID {
    width: 90%;
    height: 78%;
  }
  .question_ID .select_question {
    max-height: 700px;
  }
  .bound {
    gap: 20px;
  }
  .academicCategoryWrap {
    gap: 20px;
  }
  .select_question .primary_tag {
    width: 88%;
  }
  .select_question .Secondary_tag {
    width: 88%;
  }
  .select_question .free_tag {
    width: 88%;
  }
  .tagWrap {
    display: flex;
    gap: 2px;
  }
  .sub_head_content_table {
    height: 55vh;
    overflow: scroll;
  }
  .sub_head_content_table_height {
    height: 64vh !important;
  } 
  .import_question {
    left: 0 !important;
  }
}

/*****media querry for laptop mode ****/
@media screen and (min-width: 1024px) and (max-width: 1439px) and (min-height: 600px) {
  .question_ID {
    width: 96%;
    height: 52%;
  }
  .select_question .primary_tag {
    width: 85%;
  }
  .select_question .Secondary_tag {
    width: 85%;
  }
  .select_question .free_tag {
    width: 85%;
  }
  .tagWrap {
    display: flex;
    gap: 2px;
  }
  .sub_head_content_table {
    height: 343px;
  }
  .table_content_report {
    height: 46vh;
  }
}

/**** destop *****/
@media screen and (min-width: 1440px) and (min-height: 798px) {
  .table_content_report {
    width: 100%;
    height: 82vh;
  }
  .sub_head_content_table {
    height: 64vh;
  }
  .sub_head_content_table_height {
    height: 64vh !important;
  } 
  .paginationWrap {
    bottom: -10px !important;
}
}

/***reupload button***/
.reuploadButton {
  background-color: #00abfb;
  height: 30px;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  color: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.reuploadButton:disabled {
  background-color: #00abfb78;
  cursor: not-allowed; /* Optional: change the cursor to indicate it's disabled */
}
.docId {
  padding: 16px 20px 16px 24px !important;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px;
  color: #272727;
}
.formatDateTime {
  display: block;
  width: 130px;
}
.docName {
  display: block;
  width: 140px;
  overflow-wrap: break-word;
}
.questionbankId {
  display: block;
  width: 200px;
}
.docStatus {
  display: block;
  width: 75px;
  text-transform: capitalize;
}

.userName {
  display: block;
  width: 235px;
}
.generationTime {
  display: block;
  width: 130px;
}
.totalTime {
  display: block;
  width: 100px;
}
.reuploadButtonwrap {
  display: block;
  width: 85px;
}
.messageDisplay {
  display: block;
  width: 406px;
}
.importRecordId {
  display: block;
  width: 40px;
}
.noDataText {
  display: flex;
  justify-content: center;
  padding: 30px;
}

/* Start of new css */
.table_height {
  height: 84vh;
}

.sub_head_content_table_height {
  height: 62vh;
}   
/* End of new css */

