.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.toasts-wrapper {
  position: absolute;
  bottom: 60px;
  right: 20px;
}

.toast {
  border: 2px solid transparent;
  color: white;
  background-color: #25ab5b;
  max-width: 480px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 16px;
  display: flex;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  z-index: 999999999999;
}

.toast__text {
  text-transform: none;
  padding: 16px 24px;
  line-height: 1.4;
}

/* Default styles for toast close button */
.toast__close-btn {
  border: none;
  background-color: transparent;
  background-image: url('../src/assets/images/cancelButton.svg');
  background-size: cover;
  background-position: center;
  font-size: 16px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.3s ease; /* Smooth transition for color change */
}

/* Close button styles for success */
.close-btn--success {
  background-color: #468847;
}

/* Close button styles for error */
.close-btn--error {
  background-color: #dd5a43;
}

/* Optional: Hover effect for both success and error states */
.toast__close-btn:hover {
  transform: scale(1.1); /* Slightly scale up the button on hover */
}

.toast--error {
  background-color: #f2dede;
  color: #b94a48 !important; /*DD5A43*/
}
.radioBtnSize {
  width: 19px;
  height: 19px;
}
.toast--success {
  background-color: #dff0d8;
  color: #468847; /*B2C0AD*/
}
.tableHearder {
  background-color: #f4f4f4;
  color: #8e9eab;
  margin: 12px 12px 12px 0px !important;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.headerModel {
  padding: 0px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: #ffffff;
  box-shadow: 0px 8px 24px 0px #7090b01a;
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-radius: 17px 22px 0px 0px;
}
.headerText {
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex;
}
.heaserTitle {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.headerIcon {
  width: 24px;
  height: 24px;
  margin-right: -65px;
}
.headerSubtitle {
  width: 86%;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: left !important;
  margin-top: 32px !important;
}
.headerContent {
  margin-top: 12px !important;
  width: 86%;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
}
.modalSaveContainer {
  justify-content: start !important;
  justify-content: space-between;
  margin-top: 20px !important;
  width: 86%;
}
.lockModelBreaks {
  width: 100% !important;
}

.quasi_modal {
  left: -206px !important;
  width: 110vw !important;
}

@media (max-width: 1440px) {
  .lockModelBreaks {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .lockModelBreaks {
    width: 100%;
  }
}

.lockModalCenterDiv {
  width: 40% !important;
  height: 60% !important;
}

.alertHeaderLayout {
  position: absolute;
  top: 0;
  left: 20%;
  width: 39% !important;
  background-color: white;
  height: 288px !important;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textUnderLine {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 0.6em;
}
.highlightModel {
  position: absolute;
  height: 970px !important;
  top: -127% !important;
  left: -52% !important;
  width: 2070px !important;
}

/**** Desktop and large screens (1024px to 1920px) ****/
@media screen and (min-width: 1024px) and (max-width: 1920px) {
  .highlightModel {
    top: -84% !important;
    left: -26% !important;
    height: 260% !important;
    width: 140% !important;
  }
}

/* For devices from 768px to 1148px (tablets and small laptops) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .highlightModel {
    top: -91% !important;
    left: -26% !important;
    height: 255% !important;
    width: 150% !important;
  }
}

/* For devices from 1149px to 1366px (small desktops or laptops) */
@media screen and (min-width: 1149px) and (max-width: 1366px) {
  .highlightModel {
    top: -74% !important;
    left: -59% !important;
    height: 255% !important;
    width: 208% !important;
  }
}

.questionIdTags {
  width: -webkit-fill-available;
}
.showPassword {
  cursor: pointer;
  position: relative;
  top: -44px;
  left: 87%;
}
.custom-checkbox input[type='checkbox'] {
  display: none;
}

.custom-checkbox .checkmark {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 5px; /* Optional: For rounded corners */
  background-color: white;
  position: relative;
  cursor: pointer;
  margin-right: 4px;
  margin-top: 2px;
}

.custom-checkbox input[type='checkbox']:checked + .checkmark {
  background-color: #4caf50;
  border-color: #4caf50;
}

.custom-checkbox input[type='checkbox']:checked + .checkmark::after {
  content: '✔';
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.testNameHeader{
 
  gap: 23px;
  display: flex;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 0px;
  

}
.testNameHeaderBtn{
border: 1px solid #C7CCD1;
border-radius: 8px ;
padding: 12px;
line-height: 0px;
}
.freeTagsWidth{
  width: -webkit-fill-available !important;
}
.select-focused:focus {
  outline: 1.5px solid black !important; /* Adjust the color and width as needed */
  border-radius: 4px !important; /* Optional: Add rounded corners if desired */
}
.HeaderInfo{
  margin-left: 38px;
  margin-bottom: 20px;
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 26px;
}
.tox-sidebar-wrap{
  pointer-events: all;
}

.blankSpan{
  position: relative;  
    left: 52px;
   
}