@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/*********************************************************************************** USER SIGN IN PAGE START FROM HERE **********************************************************************/

/********************************* BELOW PART WILL BE COMMON IN ALL COMPONENT PAGES CSS *****************************************/

.signup_page {
  font-family: 'Open Sans', sans-serif;
}

.signup_page .section1 {
  box-shadow: 0px 16px 40px 0px #7090b01a;
  background: linear-gradient(277.39deg, #a1c4fd 0%, #c2e9fb 85.46%, #dff5ff 98.41%);
  width: 50%;
  float: left;
  height: 100vh;
  margin: 0;
  padding: 0;
  border-radius: 0 20px 20px 0; 
}

.signup_page .section1 .ims_logo {
  display: block;
  margin: 1em 0em 0em 2em;
}

.signup_page .section1 .ims_logo .logo {
  height: 120px;
  width: 120px;
}
.error_wrap {
  display: flex;
  height: 30px;
  font-size: 14px;
  gap: 5px;
  margin: 0.5em 1.5em 0em 2.2em;
}
.mail_error_wrap {
  display: flex;
  gap: 5px;
  height: 30px;
  margin: 5em 1.5em 0.5em 2.2em;
}
.warningIcon {
  position: relative;
  top: 1px;
  width: 20px;
  height: 20px;
  background-image: url('../images/warning.svg');
  background-size: cover;
}
.validation_error {
  color: red;
  width: 89%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.validationError {
  height: 22px;
  color: red;
  text-align: right;
  width: 89%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  margin-left: 30px;
  font-weight: 400;
  line-height: 22px;
}
.validation_mailError {
  color: red;
  width: 89%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.signup_page .section1 .main_area {
  display: flex;
}

.signup_page .section1 .text {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  width: 40%;
  height: 14vh;
  border-radius: 15px;
  font-size: 14px;
  margin-left: 15%;
  line-height: 2em;
}

.signup_page .section1 .text_area1 {
  margin-left: 10%;
  margin-top: 2%;
  padding-top: 1%;
  position: relative;
}

.signup_page .section1 .text_area2 {
  position: relative;
  margin-left: 33%;
  margin-top: -13.5%;
}

.signup_page .section1 .public {
  display: flex;
  justify-content: end;
  height: 30vh;
  margin-top: -6%;
}
.signup_page .section1 .public .public_img {
  height: 280px;
  width: 250px;
}

.signup_page .section1 .public .one {
  margin-right: 20%;
  margin-top: -10%;
}

.signup_page .section1 .public .two {
  margin-top: -10%;
  margin-right: 58%;
}

/**** new css ****/
.signup_page .section1 .text_area {
  transform: scale(0.82);
}
.signup_page .section1 .text_area .title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  text-align: left;
  margin: 0px 0 15px 0;
}
.signup_page .section1 .text_area .title_text {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.signup_page .section1 .public_img1 {
  width: 530.44px;
  height: 310.55px;
  position: absolute;
  bottom: -14px;
  left: 90px;
  transform: scale(0.9);
}

/****** Section 2 ******/

.signup_page .section2 {
  background-color: #fff;
  width: 50%;
  float: left;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signup_page .section2 .nav_section {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 2%;
  margin-right: 10%;
}

.signup_page .section2 .nav_section a {
  text-decoration: none;
}

.signup_page .section2 .nav_section .nav_text {
  cursor: pointer;
  color: #454545;
  font-size: 14px;
  text-decoration: none;
  padding: 5px;
}

.signup_page .section2 .nav_section .nav_text:hover {
  color: black;
  font-weight: bold;
}

.signup_page .section2 .nav_section .fa_solid {
  color: lightgray;
  margin-right: 5px;
}

/*** USER SIGN IN AREA ***/

.signup_page .section2 .signup_form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_page .section2 .signup_form .user_form {
  background-color: #ffffff;
  width: 75%;
  transform: scale(0.92);
  height: 96vh;
  border-radius: 15px;
  box-shadow: 6px 7px 24px 12px #7090b026;
}

/**************************************************************** UPTO THIS PART ALL PAGES COMPONENT CSS WILL BE SAME ****************************************/

/**************************************************************** NOW BELOW THIS PART , ALL COMPONENT CSS WILL BE DIFFERENT (#REMEINDER#) ***********************/
.signup_page .section2 .signup_form .user_form .text_signin {
  width: fit-content;
  margin: 1.5em 1.5em 0em 1.5em;
}

/**** USER EMAIL AND PASSWORD AREA ***/

.signup_page .section2 .signup_form .user_form .user_info {
  margin: 0em 1em 1em 1em;
  transform: scale(0.9);
}

.signup_page .section2 .signup_form .user_info .mail_and_pass label {
  line-height: 3.2em;
  font-size: 14px;
}
.signup_page .section2 .signup_form .user_info .mail_and_pass label::after {
  content: ' *';
  color: red;
}
.signup_page .section2 .user_info .mail_and_pass input::placeholder {
  font-size: 12px; 
}
.signup_page .section2 .user_info .mail_and_pass input {
  width: 90%;
  height: 8vh;
  display: flex;
  font-size: 14px;
  padding-left: 1.5em;
  border-radius: 12px;
  border: 1.5px solid #ecf3f5;
}

.signup_page .section2 .signup_form .user_info .mail_and_pass input:focus {
  border: 1px solid lightgrey;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0) 0.1;
}

/**** FOREGT PASSWORD AREA AND OTHER OPTIONS ***/
.signup_page .section2 .signup_form .user_form .user_options {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  line-height: 3em;
}

.signup_page .section2 .signup_form .user_form .user_options .forget_pass {
  margin-left: 43%;
  cursor: pointer;
  text-decoration: 'underline';
}

/*added the hover effect for remember me in signin */
.signup_page .section2 .signup_form .user_form .user_options .user_memo {
  cursor: pointer;
  color: #272727;
  font-size: 14px;
  font-weight: 400;
  margin-left: 6px;
}

.signup_page .section2 .user_info input {
  width: 16px;
  height: 16px;
  border: 1.5px solid #c7ccd1;
  cursor: pointer;
}

/**** SIGN UP BUTTON AREA ***/
.signup_page .section2 .signup_form .signin_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.5em;
}

.signup_page .section2 .signup_form .signin_button .bt_signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  font-size: 18px;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}

/************************************************************** USER  SIGN IN PAGE ENDS HERE **************************************************************************/

/****************************************** FORGET PASSWORD PAGE AFTER CLICKING ON FORGOT PASSWORD LINK IN SIGN IN PAGE STARTS FROM HERE *******************************************/
.signup_page .section2 .signup_form .user_form .forget_password {
  width: fit-content;
  margin: 1.5em 1.5em;
}

.signup_page .section2 .signup_form .user_form .forget_password .some_text {
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  margin: 0.5em 0em 0em 0em;
}

.signup_page .section2 .signup_form .user_form .user_pass_info {
  margin: 2em 2.5em;
}

.signup_page .section2 .signup_form .user_form .user_pass_info .setting_new_pass label::after {
  content: ' *';
  color: red;
}
.signup_page .section2 .signup_form .user_form .user_pass_info .setting_new_pass input::placeholder {
  font-size: 12px; 
}
.signup_page .section2 .signup_form .user_form .user_pass_info .setting_new_pass input {
  width: 90%;
  height: 8vh;
  display: flex;
  font-size: 14px;
  padding-left: 1.5em;
  border-radius: 12px;
  border: 1.5px solid #ecf3f5;
  margin-top: 3%;
}

.signup_page .section2 .signup_form .user_form .forget_pass_bt {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 8.5em;
}

.signup_page .section2 .signup_form .user_form .forget_pass_bt_signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  font-size: 18px;
  height: 8vh;
  width: 80%;
  cursor: pointer;
}
/********************************************* FORGET PASSWORD PAGE AFTER CLICKING ON FORGOT PASSWORD LINK IN SIGN IN PAGE ENDS HERE ************************************************/

/*********************************************** AFTER CLICKING ON EMAIL LINK -> SETTING NEW PASSOWRD PAGE APPEARS ******************************************************************/

/*************************************************************************** SETTING NEW PASSWORD PAGE STARTS FROM HERE *************************************************************/
.signup_page .section2 .signup_form .create_new_pass {
  width: fit-content;
  margin: 1.5em 1.5em 0.5em 1.5em;
}

.signup_page .section2 .signup_form .user_info {
  margin: 3em 2.5em;
}

.signup_page .section2 .signup_form .user_info .new_pass label {
  line-height: 3.2em;
  font-size: 14px;
}
.signup_page .section2 .signup_form .user_info .new_pass .required_field {
  color: red;
}
.signup_page .section2 .signup_form .user_info .new_pass input::placeholder {
  font-size: 12px;
}
.signup_page .section2 .signup_form .user_info .new_pass input {
  width: 90%;
  height: 8vh;
  display: flex;
  font-size: 14px;
  padding-left: 1.5em;
  border-radius: 12px;
  border: 1.5px solid #ecf3f5;
  margin-top: 5px;
  margin-bottom: 12px;
}

.signup_page .section2 .signup_form .setting_new_pass_signin_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_page .section2 .signup_form .setting_new_pass_signin_button .bt_setting_new_pass_signup {
  background-color: lightgray;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 16px 40px rgba(112, 144, 176, 0.2);
  color: #fff;
  height: 8vh;
  width: 80%;
  font-size: 18px;
  cursor: pointer;
}

/************************************************************ BELOW CODE WILL BE DYNAMICALLY CALLED ******************************************/

/***** BELOW CODE WILL ACTIVE ONLY WHEN NEW PASSWORD BAR REQUIEMENTS WILL NOT BE FULLFILLED *********/
.signup_page .section2 .signup_form .new_pass .hidden_alert {
  display: none;
}

/**** THIS WILL BE ACTIVE ONLY WHEN MIN LENGTH CONDITION FULL FILLED ****/
.signup_page .section2 .signup_form .user_form .new_pass .hidden_alert .min_length {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN STRONG PASSWORD CONDITION FULL FILLED ****/
.signup_page .section2 .signup_form .user_form .new_pass .hidden_alert .strong_password {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN BOTH THE CONDITION FULL FILLED ****/
.signup_page .section2 .signup_form .user_form .new_pass .hidden_alert li {
  color: #26ab5b;
}

/**** THIS WILL BE ACTIVE ONLY WHEN BOTH THE PASSWORDS MATCH ****/
.signup_page .section2 .signup_form .user_form .password_options {
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1em;
  bottom: 1px;
}
/*added the hover effect for remember me in create passowrd screen */
.signup_page .section2 .signup_form .user_form .password_options .user_memo {
  cursor: pointer;
  color: #272727;
  font-size: 14px;
  font-weight: 400;
}
.signup_page .section2 .signup_form .user_form .password_options .user_memo:hover {
  color: black;
  font-weight: bold;
}
.signup_page .section2 .signup_form .user_form .password_options .hidden_password_matchbox {
  margin-left: auto;
  margin-right: 5%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #26ab5b;
  display: flex;
  align-items: center;
  cursor: context-menu;
  height: 22px;
}

.signup_page .section2 .signup_form .user_form .password_options .hidden_password_matchbox .tick {
  width: 20px;
  height: 20px;
  margin-right: 2px;
  background: linear-gradient(220.53deg, #9bf763 0%, #25ab5b 76.95%);
  border-radius: 50%;
  border: 1.5px solid #ffffff;
}

/********************************************************************************** SETTING NEW PASSWORD PAGE ENDS HERE ************************************************************/

/********************************************** RESPONSIVE SM *************************/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* commanded the diplay none for the section 1 and added the some css for responsiveness in tablet mode */
  .signup_page .section1 {
    width: 100%;
    height: 626px;
    border-radius: 0px;
  }
  .signup_page {
    display: flex;
    flex-direction: column;
  }
  .signup_page .section2 {
    width: 100%;
    float: none;
    transition: all 0.4s ease;
  }
  .signup_page .section2 .signup_form .user_form {
    width: 80%;
    height: 70vh;
  }
  .signup_page .section1 .main_area {
    margin-left: 1em;
  }
  .signup_page .section1 .public_img1 {
    width: 597.44px;
    height: 335.55px;
    bottom: unset;
    left: 140px;
    top: 312px;
  }
  
  .signup_page .section2 .signup_form .setting_new_pass_signin_button {
    margin-top: 3em;
  }
  .signup_page .section2 .signup_form .user_form .forget_pass_bt {
    top: 13em;
  }
}

/********************************************** For ticks and bullets  *************************/
.custom_list {
  margin-bottom: 13px;
  list-style-type: none; /* Remove default list styling */
}

/* Bullet style */
.custom_list .bullet::before {
  content: '\2022'; 
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: rgba(255, 136, 0, 1); 
  font-size: 20px; 
  position: absolute;
  left: -15px;
}
.bullet {
  color: rgba(255, 136, 0, 1);
}
.tick {
  cursor: auto;
  margin-left: 25px !important;
  color: rgba(38, 171, 91, 1); 
}
/* Tick style */
.custom_list .tick::before {
  content: ''; 
  background-image: url('../images/Tick_mark.svg');
  background-size: cover;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: -22px;
  transform: translateY(-50%);
  cursor: context-menu;
}
/******* new media query *******/
/**** destop *****/
@media screen and (min-width: 1440px) and (min-height: 798px) {
  .signup_page .section1 .ims_logo {
    margin: 1em 0em 0em 4em !important;
  }
  .signup_page .section1 .main_area {
    margin-left: 4em;
  }
  .signup_page .section1 .text_area {
    transform: scale(0.95);
  }
  .signup_page .section1 .text_area .title {
    margin: 0px 0 25px 0 !important;
  }
  .signup_page .section1 .public_img1 {
    bottom: -22px;
    height: 560.55px !important;
    left: -25px !important;
    width: 740.44px !important;
  }
  .signup_page .section2 .signup_form .user_form .text_signin {
    margin: 3em 1.5em 1em 1.5em;
  }
  .signup_page .section2 .signup_form .signin_button {
    margin-top: 10em;
  }
  .signup_page .section2 .signup_form .create_new_pass {
    margin: 3em 1.5em 1em 1.5em;
  }
  .signup_page .section2 .signup_form .user_form .forget_password {
    margin: 3em 1.5em;
  }
  .signup_page .section2 .signup_form .user_form .forget_pass_bt {
    position: relative;
    top: 17em;
  }
 
 .signup_page .section2 .signup_form .setting_new_pass_signin_button {
    margin-top: 6em;
  }
}
/*****media querry for laptop mode ****/
@media screen and (min-width: 1024px) and (max-width: 1439px) and (min-height: 600px) {
  .signup_page .section1 .text_area {
    transform: scale(0.78);
  }
  .signup_page .section1 .public_img1 {
    width: 490.44px;
    height: 320.55px;
    bottom: 0;
    left: -3px;
    transform: scale(0.98);
  }
  .signup_page .section2 .signup_form .user_form {
    width: 80%;
  }
  .signup_page .section2 .signup_form .create_new_pass {
    margin: 2em 1.5em 1em 1.5em;
  }
  .signup_page .section2 .signup_form .user_form .text_signin {
    margin: 2em 1.5em 1em 1.5em;
  }
  .signup_page .section2 .signup_form .user_form .user_options .forget_pass {
    margin-left: 7em !important;
  }
  .signup_page .section2 .signup_form .user_form .forget_password {
    margin: 3em 1.5em;
  }
  .signup_page .section2 .signup_form .signin_button {
    margin-top: 4.5em;
  }
  .signup_page .section2 .signup_form .setting_new_pass_signin_button {
    margin-top: 0em;
  }
  .signup_page .section2 .signup_form .user_form .forget_pass_bt {
    top: 8em;
  }
}

/********************************************** For ticks and bullets  *************************/
.custom_list li {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  position: relative;
  margin-left: 25px; 
  margin-bottom: 6px;
  cursor: context-menu;
}
