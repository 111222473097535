.create_question {
  padding-left: 3%;
  padding-top: 2%;
  height: 10vh;
}

.create_question .create_title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  font-family: 'IBM Plex Sans', sans-serif;
  margin-bottom: 1%;
}
.academicCategoryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 33px;
  padding-top: 3.2%;
}
.Categorybound {
  margin-bottom: 0px !important;
}
.selectFormatWrap {
  width: 100%;
}
.tagWrap {
  display: flex;
  gap: 35px;
  position: relative;
  bottom: 30px;
  width: 100%;
}
.bound {
  display: flex;
  flex-direction: row;
  gap: 33px;
  margin-bottom: 25px;
  padding-top: 0;
}
.boundWrap {
  margin-bottom: 0px !important;
}
.bound .templateGroup {
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}
.categoryList {
  position: relative;
}
.AcademicYearWrap {
  position: relative;
  top: 55px;
}
.bound .templateGroup select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 2px rgb(203, 201, 201);
  outline: none;
  border: none;
  width: 280px !important;
  height: 50px;
  border-radius: 12px;
  margin-top: 10px;
  padding-left: 10px;
}

.bound .templateGroup .textInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #ecf3f5;
  box-shadow: 0px 0px 2px rgb(203, 201, 201);
  width: 470px;
  height: 50px;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  color: #272727;
  opacity: 1;
}
.bound .templateGroup .textInput::placeholder {
  color: #272727; /* Placeholder text color */
  opacity: 60%; /* Adjust opacity if needed */
}
.bound .verbalBox {
  width: 376px;
}

.bound .templateDrop .varc {
  width: 178px;
}

.bound .templateDrop .templateDrop_item .templateGroup .fullBgmarks {
  background: #ecf3f5;
  padding-right: 10px;
}

.create_question .create_question_screen_list span {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #c7ccd1;
}

.create_question .create_question_screen_list .active {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #00abfb;
}
.create_question .create_question_screen_list .completedCode {
  color: black;
}
.question_ID {
  width: 97%;
  height: 45vh;
  transform: scale(0.92);
}

.question_ID .title {
  width: 100%;
  height: 10vh;
  background-color: #ffffff;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 2%;
  margin-top: 1%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: 0px 0px 10px rgb(238, 235, 235);
}

.question_ID .title span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.question_ID .select_question {
  width: 94%;
  background-color: #ffffff;
  padding-right: 4%;
  padding-left: 4%;
  padding-top: 1.5%;
  margin-top: 2px;
  padding-bottom: 5%;
  overflow-y: auto;
  height: 35vh;
}
.question_ID .select_question::-webkit-scrollbar {
  width: 10px; /* Set the width of the vertical scrollbar */
  height: 10px; /* Set the height of the horizontal scrollbar */
}
.question_ID .select_question::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.question_ID .select_question::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.question_ID .basicInfo_selectquestion {
  height: 45vh !important;
  padding-bottom: 7% !important;
  padding-right: 3% !important;
  padding-left: 3% !important;
  width: 96% !important;
}

.question_ID .select_question .top_bar .top_bar_title span {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  font-family: 'IBM Plex Sans', sans-serif;
}
.select_question .questionList {
  width: 100%;
}
.select_question .list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 3.2%;
  padding-bottom: 3.2%;
}

.select_question .list .box {
  flex: 0 0 calc(33.333% - 30px);
  width: 100%;
  height: 140px;
  box-shadow: 0px 0px 10px rgb(224 213 213);
  border-radius: 20px;
  position: relative;
}

.select_question .list .active_box {
  flex: 0 0 calc(33.333% - 30px);
  width: 340px;
  height: 140px;
  box-shadow: 0px 0px 6px #00abfb;
  border-radius: 20px;
  position: relative;
}

.select_question .list .box .title_list_box,
.select_question .list .active_box .title_list_box {
  left: 15px;
  position: absolute;
}

.select_question .list .box .question_list_box,
.select_question .list .active_box .question_list_box {
  right: 20px;
  bottom: 0px;
  position: absolute;
}

.select_question .list .box .question_list_box p,
.select_question .list .active_box .question_list_box p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .list .box .question_list_box p span,
.select_question .list .active_box .question_list_box p span {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .list .box .smallBall,
.select_question .list .active_box .smallBall {
  right: 45px;
  position: absolute;
}

.select_question .list .box .largeBall,
.select_question .list .active_box .largeBall {
  right: 0px;
  position: absolute;
}

.select_question .list .box .largeBall svg,
.select_question .list .active_box .largeBall svg {
  border-top-right-radius: 20px;
}
.saveButton_questionBank {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}
.questionBankSaveButton {
  width: 98%;
  height: 10vh;
  display: flex !important;
  justify-content: flex-start !important;
  background-color: #ffffff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 1% 2%;
  box-shadow: 0px 0px 10px rgb(238, 235, 235);
}
.saveButton_questionBank .cancel {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'IBM Plex Sans', sans-serif;
}
.select_question .lodInfo {
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}
.select_question .info {
  display: flex;
  flex-wrap: wrap;
  gap: 42px;
  padding-top: 3.2%;
  padding-bottom: 2%;
}
.select_question .levelInfo {
  padding-top: 2.2% !important;
}
.select_question .free_tag {
  margin-bottom: 30px;
}

.select_question .free_tag .tag .tag_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .free_tag .tag .tag_desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #212529;
  margin-top: 10px;
}

.select_question .free_tag .tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #ecf3f5;
  box-shadow: 0px 0px 2px rgb(203, 201, 201);
  border-radius: 12px;
  max-width: 510px;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select_question .free_tag .tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.select_question .free_tag .tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.select_question .free_tag .tag_search .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  padding: 5px 5px 5px 20px;
  width: 100%;
  position: relative;
}

.select_question .free_tag .tag_search .input_search input,
.select_question .free_tag .tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.select_question .free_tag .tag_search .cancel_button {
  float: right;
}

.select_question .free_tag .tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list_input_primary {
  position: absolute;
  top: 55px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  z-index: 50;
}

.list_input_primary .multiTagWrap {
  padding: 8px 5px;
  text-align: left;
  border: none;
  background: none; 
  display: flex;
}

.list_input_primary .multiTagWrap:hover span {
  background-color: #00abfb;
  color: white;
}

.list_input_primary .multiTagWrap span {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  word-break: break-word;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.selected_tagsWrap {
  gap: 5px;
}
.Secondary_tag .tag .tag_search .input_search .selected_tags {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.free_tag .tag .tag_search .input_search .selected_tags {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.selectedFreetags{
  white-space: normal;
  overflow-wrap: break-word;
  display: block !important;
  width: auto;
  max-width: 245px;
}
.select_question .Secondary_tag {
  margin-bottom: 30px;
}

.select_question .Secondary_tag .tag .tag_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .Secondary_tag .tag .tag_desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #212529;
  margin-top: 10px;
}

.select_question .Secondary_tag .tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #ecf3f5;
  box-shadow: 0px 0px 2px rgb(203, 201, 201);
  border-radius: 12px;
  max-width: 510px;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select_question .Secondary_tag .tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.select_question .Secondary_tag .tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.select_question .Secondary_tag .tag_search .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  padding: 5px;
  width: 100%;
  position: relative;
}

.select_question .Secondary_tag .tag_search .input_search input,
.select_question .Secondary_tag .tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.select_question .Secondary_tag .tag_search .cancel_button {
  float: right;
}

.select_question .Secondary_tag .tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Primry tag*/
.select_question .primary_tag {
  margin-bottom: 24px;
  margin-top: 20px;
}

.select_question .primary_tag .tag .tag_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .primary_tag .tag .tag_desc {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #212529;
  margin-top: 10px;
}

.select_question .primary_tag .tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1.5px solid #ecf3f5;
  box-shadow: 0px 0px 2px rgb(203, 201, 201);
  border-radius: 12px;
  max-width: 100%;
  width: 510px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select_question .primary_tag .tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.select_question .primary_tag .tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.select_question .primary_tag .tag_search .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;

  width: 100%;
  position: relative;
}

.select_question .primary_tag .tag_search .input_search input,
.select_question .primary_tag .tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.select_question .primary_tag .tag_search .cancel_button {
  float: right;
}

.select_question .primary_tag .tag_search .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Remove the clear (cross) icon from the search input */
#searchInput::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

/* Remove the clear (cross) icon from the search input for Mozilla Firefox */
#searchInput::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.select_question .info .format_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .info .format_choice {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 20px;
}

.select_question .info .format_choice div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_question .info .format_choice label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .info .format_choice input {
  margin: 0px;
  margin-right: 8px;
}

.select_question .Question_Direction {
  width: 60%;
  margin-top: 40px;
}

.select_question .Question_Direction .tox_tinymce {
  height: 200px !important;
}

.select_question .Question_Direction p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

/**** send for review***/
.select_question .send_for_review {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.send_for_review .top {
  text-align: center;
}

.send_for_review .create .line_div {
  padding: 3% 10%;
}

.send_for_review .create .line_div .line {
  border-top: 2px solid #ecf3f5;
}

.send_for_review .create {
  text-align: center;
}

.send_for_review .create h4 {
  margin: 0px;
}

/*** select box type ***/
.select_question .question_type_box {
  border: 1px solid white;
  box-shadow: 0px 0px 10px #ece4e4;
  width: 96%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
}

.select_question .question_type_box .question_type_title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.select_question .question_type_box .question_type_title svg {
  float: right;
}

.select_question .question_type_box .category {
  display: flex;
  gap: 10px;
  margin-top: 40px;
  height: 90%;
  width: 100%;
}

.select_question .question_type_box .category .sub_category {
  border-right: 1px solid gray;
  width: 18.5%;
  height: auto;
  padding-right: 10px;
}

.select_question .question_type_box .category .sub_category div {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  margin-bottom: 15px;
}

.select_question .question_type_box .category .name_category {
  width: 92%;
  position: relative;
  padding-left: 12px;
}

.select_question .question_type_box .category .name_category .selection_box {
  display: flex;
  gap: 20px;
  margin-bottom: 5%;
  flex-wrap: wrap;
}

.select_question .question_type_box .category .name_category .select_type p {
  margin-top: 0px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #212529;
}

.select_question .question_type_box .category .name_category .select_type .select_box_type {
  border: 1px solid #c7ccd1;
  padding: 13px 15px;
}

.select_question .question_type_box .category .name_category .select_type .select_box_type p {
  line-height: 14px;
  font-size: 10px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #212529;
  margin-top: 0px;
}

.select_box_type div {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.select_box_type div label {
  line-height: 14px;
  font-size: 10px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  margin-left: 5px;
}

.select_box_type div input {
  margin: 3px;
}

.saveButton_questionCreation {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
  position: relative;
  z-index: 50;
  bottom: -90px;
}

.saveButton_questionCreation .cancel {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'IBM Plex Sans', sans-serif;
}
.publishButton {
  bottom: 0px !important;
  top: 30px !important;
}

.select_question .question_creating {
  box-shadow: 0px 0px 10px #ece4e4;
  width: 60%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #00abfb;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  padding-right: 10px;
}

.select_question .question_creating .question_creating_title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.select_question .question_creating .question_creating_title svg {
  float: right;
}

.select_question .passage_text {
  width: 60%;
  margin-bottom: 40px;
}

.select_question .passage_text .tox_tinymce {
  height: 200px !important;
}

.select_question .passage_text p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .enter_answer {
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 59.9%;
}

.select_question .enter_answer .title_detail {
  width: 50%;
}

.select_question .enter_answer .random_text {
  text-align: right;
  width: 50%;
}

.select_question .enter_answer .title_enter_answer {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.select_question .enter_answer .title_enter_desc {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 0px;
}

.tinymce_text {
  margin-bottom: 50px;
}

/* Editor width can be changed from here */
.select_question .compose_text {
  width: 60%;
  margin-top: 40px;
}
.select_question .explanation_text {
  width: 60%;
  margin-top: 40px;
}

.select_question .compose_text .tox_tinymce {
  height: 200px !important;
}

.select_question .compose_text p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.explanation_text p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .Answer_text {
  width: 70%;
  margin-top: 40px;
}

.select_question .Answer_text .tox_tinymce {
  height: 200px !important;
}

.select_question .Answer_text p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
  max-width: 85%;
}

.select_question .Answer_text .select {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Answer_text .editor_box {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 100%;
}

.Answer_text .editor_box .close_answer {
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 8px 12px;
}

.Answer_text .editor_box .editor {
  width: 85%;
}

.Answer_text .editor_box .close_answer span {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 8px;
}

.Add_answer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.AddbuttonWrap, .addTableWrap, .addPassageWrap{
  background: none;
  border: none;
  padding: 0px;
}
.addAnswerWrap, .closeAnswerWrap{
  background: none;
  border: none;
  padding: 0px;
}
.addOptionBtn {
  cursor: pointer;
   border: none;
   background: none; 
   padding: 0px;  
   color: #2196f3;
   font-size: 14px;
}
.addOptionBtn:hover {
  color: #1976d2;
}

.Add_answer span {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 8px;
  color: #00abfb;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.explanation_text .explanation_video {
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
}

.explanation_text .explanation_video input,
.explanation_text .explanation_video input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 10px;
}

.select_question .explanation_line {
  width: 60%;
  margin-top: 40px;
}

.select_question .explanation_line .line {
  border: 2px solid #ecf3f5;
}

.select_question .correctAnswer {
  width: 60%;
  margin-top: 40px;
}

.select_question .correctAnswer .tox_tinymce {
  height: 200px !important;
}

.select_question .correctAnswer p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .correctAnswer textarea {
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  width: 97%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.select_question .correctAnswer_format {
  width: 60%;
  margin-top: 40px;
}

.select_question .correctAnswer_format p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_format .range_calculate {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
}

.select_question .correctAnswer_format .range_calculate .range {
  display: flex;
  align-items: center;
  width: 85%;
  gap: 40px;
}

.select_question .correctAnswer_format .range_calculate .range .from {
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_format .range_calculate .range .from input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_format .range_calculate .range .to {
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_format .range_calculate .range .to input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_range {
  width: 60%;
  margin-top: 40px;
}

.select_question .correctAnswer_range p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
}

.select_question .correctAnswer_range .range_calculate .range {
  display: flex;
  align-items: center;
  width: 65%;
  gap: 40px;
}

.select_question .correctAnswer_range .range_calculate .rangetext {
  width: 34%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate .rangetext input {
  width: 94%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_range .range_calculate .range .from {
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate .range .from input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .correctAnswer_range .range_calculate .range .to {
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.select_question .correctAnswer_range .range_calculate .range .to input {
  width: 100%;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  margin-top: 2%;
}

.select_question .answertype {
  width: 60%;
  margin-top: 40px;
}

.select_question .answertype p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
}

.select_question .answertype select {
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  width: 30%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

/*** MULTIQUESTION FILE **/

.multiquestion {
  display: flex;
  justify-content: start;
  gap: 10px;
}

.questions_remove {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 63.5%;
  margin: 20px 0px;
}

.questions_remove .titles,
.remove {
  width: 50%;
}

.remove_question {
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border: none;
  border-radius: 12px;
  padding: 8px 12px;
  float: right;
}

.question_identifier {
  width: 97%;
  height: 110px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #0f1010;
}

.blue_button {
  background-color: #00abfb;
  color: white;
  width: 197px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  cursor: pointer;
}

.blue_button_disable {
  background-color: lightgray;
  color: white;
  width: 200px;
  height: 55px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  cursor: not-allowed;
}

/* ______________________ DRAG  ______________________ */
.container {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  gap: 5px;
}

.draggable {
  padding: 1rem;
  cursor: move;
  width: 50%;
}

.draggable.dragging {
  opacity: 0.5;
}

.multiquestionbutton {
  background-color: #00abfb;
  color: white;
  width: 185px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  margin: 5px;
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: 0px 5px 10px #e8dcdc;
  cursor: pointer;
}

.multiquestionbuttonBlue {
  background-color: #ffffff;
  color: rgb(9, 7, 7);
  width: 185px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: 0px 5px 10px #e8dcdc;
  cursor: pointer;
  margin: 5px;
}

.red_mendantory {
  color: red;
}

.checked_full {
  background-color: #e9f8ff;
}

/*** MASTERS CATEGORY **/

.master_category {
  width: 70%;
}

.Master_div {
  display: flex;
  justify-content: center;
}

/* _______________________ question-bank ____________________________ */
.question_bank_table_top {
  padding: 25px;
  height: 79vh;
  width: 83.5vw;
}

.question_bank {
  width: 100%;
  height: 100%;
}

.question_bank .question_bank_report {
  display: flex;
  align-items: center;
  width: 100%;
  height: 18vh;
  transform-origin: left;
}

.question_bank .question_bank_report .results {
  display: flex;
  width: 65%;
  height: 108px;
  gap: 30px;
}

.question_bank .question_bank_report .results .approved {
  width: 100%;
  max-width: 277px;
  height: 108px;
  background-color: #ffffff;
  outline: 0.5px solid #26ab5b;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .approved .box {
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 7px;
}

.question_bank .question_bank_report .results .approved .box .icons {
  width: 56px;
  height: 56px;
  background-color: #e9fcf1;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .approved .box .score {
  width: 170.47px;
  height: 60px;
}

.question_bank .question_bank_report .results .approved .box .score p {
  margin: 0;
  font-size: 16px;
  color: #212529;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.question_bank .question_bank_report .results .approved .box .score span {
  margin: 0;
  font-size: 28px;
  color: #212529;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  line-height: 32px;
}

.question_bank .question_bank_report .results .Rejected {
  max-width: 277px;
  width: 100%;
  background-color: #ffffff;
  height: 108px;
  outline: 0.5px solid #ff5757;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Rejected .box {
  width: 230px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 7px;
}

.question_bank .question_bank_report .results .Rejected .box .icons {
  width: 55.8px;
  height: 56px;
  background-color: #fff2f1;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Rejected .box .score {
  width: 170.47px;
  height: 60px;
}

.question_bank .question_bank_report .results .Rejected .box .score p {
  margin: 0px;
  font-size: 16px;
  color: #212529;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.question_bank .question_bank_report .results .Rejected .box .score span {
  margin: 0px;
  font-size: 28px;
  color: #212529;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  line-height: 32px;
}

.question_bank .question_bank_report .results .Review {
  max-width: 277px;
  width: 100%;
  background-color: #ffffff;
  height: 108px;
  outline: 0.5px solid #ffcf00;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Review .box {
  width: 230px;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 7px;
}

.question_bank .question_bank_report .results .Review .box .icons {
  width: 55.8px;
  height: 56px;
  background-color: #fff9e8;

  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank .question_bank_report .results .Review .box .score {
  width: 145.47px;
  height: 60px;
}

.question_bank .question_bank_report .results .Review .box .score p {
  margin: 0px;
  font-size: 16px;
  color: #212529;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.question_bank .question_bank_report .results .Review .box .score span {
  margin: 0px;
  font-size: 28px;
  color: #212529;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  line-height: 32px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 230px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.add_question {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 108px;
  position: relative;
  left: 60px;
}

.table_content_report {
  width: 82.5vw;
  overflow-y: scroll;
  background-color: #ffffff;
  margin-top: 15px;
  height: 48vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.table_content_report::-webkit-scrollbar {
  width: 10px; /* Set the width of the vertical scrollbar */
}
.table_content_report::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.table_content_report::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.table_content_report .top_content_table {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table_title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 35%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
}

.table_content_report .top_content_table .search_listing {
  width: 60%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 75px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  background-color: none !important;
}
input:-internal-autofill-selected {
  background-color: none !important;
}
.search_listing .cancel_button {
  float: right;
}

.search_listing .cancel_button button {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_listing .tag_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.search_listing .input_searchs {
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_searchs input,
.search_listing .input_searchs input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 0px;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}
.sub_head_content_table {
  height: 80%;
}
.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 13px;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 22px;
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
  width: 8%;
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
  width: 105px;
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
  width: 66px;
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.question_id_tag {
  display: inline-flex;
  align-items: center;
}

.question_id_tag .id {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-decoration: underline;
  line-height: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  margin-left: 12px;
}
.question_id_tag .idCursor {
  cursor: pointer;
}
.question_id_tag .tag {
  width: 100%;
  text-align: center;
  margin-left: 20px;
  word-break: break-word; /* Forces word breaks */
  overflow-wrap: break-word; /* Ensures wrapping */
  max-width: 400px;
}

.question_id_tag .tag p {
  font-size: 12px;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 0px;
}

.question_id_tag > .tag > span {
  font-size: 10px;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  height: 14px;
  background-color: #e9f8ff;
  border-radius: 50px;
}

.general_button {
  width: 90px;
  height: auto;
  border-radius: 50px;
  background-color: #f4f5f6;
  border: none;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}
.generalButtonWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.general_tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.author_tag {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
  text-align: center;
}

.created_date,
.lod {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
}

.status {
  display: flex;
  text-align: center;
}

.action {
  margin-right: 26px;
  position: relative;
}

.status span {
  width: 62px;
  height: 20px;
  border: 0.3px solid;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.underline_active {
  border-bottom: 5px solid #00abfb;
}

.action_button {
  box-shadow: 0px 0px 10px #ddd4d4;
  position: absolute;
  left: -105px;
  top: 28px;
  width: 90px;
  padding: 7px 15px;
  border-radius: 10px;
  z-index: 60;
  background: white;
}

.action_button .edit {
  padding: 5px 0px;
}

.action_button .duplicate {
  padding: 5px 0px;
}

/**** PAGINATION**/
.listpaginationWrap {
  width: 82.5vw;
  height: 13vh;
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.pointer {
  cursor: pointer;
}
.selectedquestion {
  font-weight: 500 !important;
}
.selectQuestionWrap {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 15px;
  background: none;
  border: none;
  padding: 0px;
}
.back_button {
  border: none;
  padding: 15px 15px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
/*responsiveness in tablet mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .question_ID {
    width: 90%;
    height: 78%;
  }
  .question_ID .select_question {
    max-height: 700px;
  }
  .bound {
    gap: 20px;
  }
  .academicCategoryWrap {
    gap: 20px;
  }
  .select_question .primary_tag {
    width: 88%;
  }
  .select_question .Secondary_tag {
    width: 88%;
  }
  .select_question .free_tag {
    width: 88%;
  }
  .tagWrap {
    display: flex;
    gap: 2px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .table_content_report {
    height: 70%;
  }
}
/*****media querry for laptop mode ****/
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .question_ID {
    width: 97%;
    height: 52%;
  }
  .select_question .primary_tag {
    width: 85%;
  }
  .question_bank_table_top {
    width: 85vw;
  }
  .table_content_report {
    width: 82vw;
  }
  .listpaginationWrap {
    width: 82.2vw;
  }
  .pagination {
    width: 79vw;
  }
  .select_question .Secondary_tag {
    width: 85%;
  }
  .select_question .free_tag {
    width: 85%;
  }
  .tagWrap {
    display: flex;
    gap: 2px;
  }
}

/**** destop *****/
@media screen and (min-width: 1440px) {
  .question_ID {
    height: 58%;
  }
  .table_content_report {
    width: 82vw;
    height: 66%;
  }
  .listpaginationWrap {
    width: 82.2vw;
    height: 11vh;
  }
  .pagination {
    width: 79vw;
    bottom: 46px;
  }
}

/***css for graph-analysis dropdown response***/
.optionsGrid {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 24px;
  margin: 0 auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 24px;
}

.optionsGrid::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 6px;
  cursor: pointer;
}
.optionsGrid::-webkit-scrollbar {
  height: 7px; /* Set the height of the horizontal scrollbar */
}
.optionsGrid::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.selectdropdown {
  height: 72px !important;
  margin-bottom: 6px;
}
.optionSection {
  width: 270px;
  background: #fff;
}

.sectionTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 18px;
  text-align: left;
}

.optionsList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 275px;
}

.optionItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sentenceNote {
  font-size: 12px;
  font-style: italic;
  color: #666;
}

.radioInput {
  width: 18px;
  height: 17px;
  margin: 0;
  cursor: pointer;
  accent-color: #2196f3;
  position: absolute;
  left: 12px;
  top: 13px;
}

.inputWrapper {
  position: relative;
  display: flex;
  width: 225px;
  height: 30px;
  padding: 8px 30px 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.responseInput {
  width: 120px;
  height: 25px;
  position: absolute;
  left: 105px;
  border: none;
}

.responseInput:focus {
  border-color: #2196f3;
}

.responseInput::placeholder {
  color: #999;
}
.optionLabel {
  position: absolute;
  left: 41px;
  top: 8px;
  background: #f6f7fb;
  border-radius: 10px;
  padding: 5px 8px;
}
.deleteButton {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../images/deleteButton.svg');
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  background-color: white;
}

.deleteButton:hover {
  color: #666;
}
.addPlusButton {
  width: 20px;
  height: 20px;
  background-image: url('../images/plusButton.svg');
  display: flex;
  border: 2px solid #00abfb;
  border-radius: 7px;
}
.addButton {
  margin-top: 5px;
  background: none;
  border: none;
  color: #2196f3;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: underline;
}

.addButton:hover {
  color: #1976d2;
}
.composeText {
  margin-top: 0px !important;
  width: 100% !important;
}
.seprator {
  display: flex;
  width: 60%;
  margin: 40px 0px;
  border: 2px solid #ecf3f5;
}
.Shortquestion {
  height: 50px !important;
}
.AddTextPassage {
  margin-top: 24px;
}

.QuestionDirection {
  margin-top: 24px !important;
}
.explanationtext {
  margin-top: 27px !important;
}
.addResponsebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00abfb;
  border-radius: 10px;
  background: white;
  width: 135px;
  height: 40px;
  position: absolute;
  bottom: 35px;
  right: 20px;
}
.addResponsebtnWrap {
  width: 810px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 80px;
  right: 240px;
}
.addResponsText {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #00abfb;
}
.addIcon {
  width: 20px;
  height: 20px;
  background-image: url('../images/plusButton.svg');
}

.ComposeQuestion {
  height: 'auto';
  position: relative;
}

.questionContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.deleteButtonForQuestion {
  border: none;
  cursor: pointer;
  padding: 0px 5px 0px 5px;
  display: flex;
  align-items: center;
  background: #f6f7fb;
  justify-content: space-around;
  flex-direction: row;
  border-radius: 10px;
}

.deleteButton:hover svg {
  stroke: red;
}
.remove-text {
  padding: 3px;
}
.questionWrapper {
  display: flex;
  justify-content: space-between;
}
.compose_questionNote {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  position: relative;
  bottom: 8px;
}
/* SelectionPErRow */
.compose_heading {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-size: 18px !important;
}
.table_container {
  overflow-x: auto; 
  width: 100%;
  margin-top: 20px;
}

.table_container::-webkit-scrollbar {
  width: 10px; 
  height: 10px; 
}
.table_container::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.table_container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.table_container table {
  border-collapse: collapse;
  width: 100%;
  direction: rtl;
  box-shadow: 3px 1px 15px rgba(0, 0, 0, 0.22);
  margin-bottom: 30px;
}

.table_container td {
  border: 2px solid #ecf3f5;
  padding: 16px;
  text-align: center;
  direction: ltr;
  min-width: 150px;
}

.table_container tr td:nth-child(2) {
  min-width: 300px; /* Second column only */
}
.table_container th {
  padding: 8px;
  text-align: center;
  direction: ltr;
}
.column_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove_column {
  border: none;
  color: #272727;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  border-radius: 10px;
  padding: 8px 12px;
  font-weight: normal;
}

.custom_input_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom_radio {
  appearance: none; /* Hides default radio styling */
  width: 24px;
  height: 24px; /* Ensure height matches width */
  aspect-ratio: 1; /* Maintains a 1:1 aspect ratio */
  border: 2px solid #c7ccd1;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}

.custom_radio:checked::before {
  content: '';
  width: 11px;
  height: 11px;
  background-color: #c7ccd1;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom_input {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
  border: none;
}
.custom_input:focus {
  outline: none;
}
.remove_row_container {
  border: none !important; /* Removes the border for the remove row column */
  text-align: right;
  direction: ltr !important;
}

.remove_row {
  background-color: #f6f7fb;
  border: none;
  color: #272727;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 8px 12px;
  font-weight: normal;
}

.controls {
  margin-top: 10px;
  display: flex;
}

button {
  cursor: pointer;
  padding: 4px 8px;
}
.inline_add_answer {
  margin-right: 28px;
  margin-top: 0;
  border: none;
  background: none;
  padding: 0px;
}

.inlineShortquestion::placeholder {
  color: #8e9eab;
  font-weight: normal;
  font-size: 14px;
}
.table_container .remove_heading {
  margin-right: 5px;
  border: none;
  background: none;
  padding: 0px;
}
.sentecnceInputWrapper {
  min-height: 40px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  padding: 8px 30px 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.sentecnceInput {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  word-wrap: break-word;
  flex-grow: 1;
  margin-left: 22px;
  width: 100%;
  left: 35px;
  border: none;
}

/****  Fraction Input UI/css  ****/
.correctAnswer {
  display: flex;
  flex-direction: column;
}
.Dividor {
  display: flex;
  width: 23%;
  margin: 16px 0px;
  border: 1.5px solid #ecf3f5;
}
.NumeratorInput,
.DenominatorInput {
  align-items: center;
  background-color: #fff;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  display: flex;
  margin-top: 2%;
  padding: 10px;
  width: 20%;
  height: 27px;
}
.dropdownArrow {
  position: absolute;
  left: 34%;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 16px;
  height: 16px;
}
.select_question .Fractionanswertype {
  width: 60%;
  margin-top: 20px;
  position: relative;
  top: 15px;
}

.select_question .Fractionanswertype p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 5px;
  display: block;
}
.multisource_container {
  margin-bottom: 40px;
}
.dropdownSelectArrow {
  position: absolute;
  left: 56%;
  top: 48%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 16px;
  height: 16px;
}
.select_question .Fractionanswertype .answerFormatSelect,
.multisource_container .source_type_select {
  padding: 13px 30px 13px 10px;
  border: 2px solid #ecf3f5;
  border-radius: 10px;
  background-color: #ffffff;
  width: 40%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  appearance: none;
  cursor: pointer;
}
.multisource_container .source_type_select {
  padding: 13px 25px;
  width: 100%;
}
.question_stem {
  text-align: right;
}
.first_column_heading {
  text-align: right;
  font-weight: 500;
  padding-right: 8px;
}
.cancelBtnWrap{
  background: none;
  border: none;
  padding: 0px;
}
/*Sibling css */
.sibling-item:hover,
.more-link:hover {
  text-decoration: underline;
}

.underlined {
  text-decoration: underline;
  cursor: pointer;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.underlined span {
  background-color: #fff !important;
}
.showMoreContainer {
  display: flex; /* Ensures inline alignment */
  flex-direction: column; /* Makes the content stack vertically */
  flex-wrap: wrap;
  align-items: center; /* Vertically aligns content */
  white-space: nowrap;
}

.moreLink {
  color: #00abfb;
  cursor: pointer;
  text-decoration: underline;
}
.multisource_container .select_type p {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-weight: 400;
  line-height: 14px;
  text-underline-position: from-font;
  margin-left: 4px;
}

.sibling_div {
  display: inline;
  align-items: center;
  gap: 2px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #6f7e8d;
}
/***** hover effect css for select question type *****/
.CreateQuestionWrap {
  height: 48vh !important;
  padding-bottom: 7% !important;
  padding-right: 3% !important;
  padding-left: 3% !important;
  width: 96% !important;
}
.select_box_type.hover {
  position: relative;
  overflow: hidden;
}

.select_box_type .hover_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0000001a;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.select_box_type .hover_content.active {
  opacity: 1;
}

.select_box_type.hover .hover_content {
  opacity: 1;
}

.multisource_container .add_psg_text {
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-top: 2.2rem;
  margin-bottom: 1.2rem;
  margin-left: 5px;
}
.multisource_container .sourceContainer {
  margin-bottom: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.multisource_container .dropdownArrowSource {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 16px;
  height: 16px;
}
.multisource_container .selectLabel {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.multisource_container .passage_desc {
  width: 60%;
  margin-top: 20px;
}
.multisource_container .disabledButton {
  opacity: 0.5;
}
.multisource_container .passage_remove {
  margin: 0;
}

/* Media query for tablets and small size devices*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .question_bank .question_bank_report .results .approved {
    width: 75%;
  }

  .question_bank .question_bank_report .results .Rejected {
    max-width: 277px;
    width: 75%;
  }

  .question_bank .question_bank_report .results .Review {
    max-width: 277px;
    width: 75%;
  }

  .question_bank .question_bank_report .results {
    display: flex;
    width: 30%;
    height: 108px;
    gap: 10px;
  }
  .add_question {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 108px;
    position: relative;
    left: 37%;
  }

  .add_new_question {
    width: 70%;
  }

  .question_bank .question_bank_report .results .approved .box {
    width: 85%;
  }

  .question_bank .question_bank_report .results .Rejected .box {
    width: 85%;
  }

  .question_bank .question_bank_report .results .Review .box {
    width: 85%;
  }

  .table_content_report .top_content_table .table_title span {
    font-size: 11px;
  }
  .sub_head_content_table table tbody tr td {
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Media query for laptops*/
@media only screen and (min-width: 1024px) and (max-width: 1920px) {
  .question_bank .question_bank_report .results .approved {
    width: 45%;
  }

  .question_bank .question_bank_report .results .Rejected {
    width: 45%;
  }

  .question_bank .question_bank_report .results .Review {
    width: 45%;
  }

  .question_bank .question_bank_report .results .approved .box {
    width: 93%;
  }

  .question_bank .question_bank_report .results .Rejected .box {
    width: 93%;
  }

  .question_bank .question_bank_report .results .Review .box {
    width: 93%;
  }

  .question_bank .question_bank_report .results {
    width: 50%;
    gap: 10px;
  }

  .add_question {
    width: 24%;
    left: 20%;
  }
}

.templateDrop {
  margin-top: -10px;
}

.nonResizable {
  resize: none;
}
.selectedPrimaryTag {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  overflow: hidden;
  word-break: break-word;
}
.title_list_name {
  max-width: 95%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}