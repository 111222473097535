.pagination {
    display: flex;
    width: 90%;
    margin-left: 20px;
    justify-content: flex-end;
    padding: 6px;
    border-radius: 20px;
    position: absolute;
    bottom: 39px;
    background: #f6f7fb;
    box-shadow: 0px 0px 41px 0px #00000008;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
  }
  
  .pagination .number {
    display: flex;
    margin-right: 10px;
    align-items: center;
    color: #8e9eab;
  }
  
  .pagination .change {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .pagination .line {
    display: flex;
    align-items: center;
  }
  
  .pagination .change .changeButton {
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    width: auto;
    height: auto;
    padding: 7px;
    font-size: larger;
    background-color: #ffffff;
  }
  .pagination .change .changeButton:disabled {
    cursor: auto;
  }
  .pagination .change .prevButton {
    content: '';
    display: inline-block;
    background-image: url('../images/Previous_enabled.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 19px;
    height: 17px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  
  .pagination .change .nextButton {
    content: '';
    display: inline-block;
    background-image: url('../images/Next_enabled.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 19px;
    height: 17px;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }
  .pagination .change .prevButton:disabled {
    content: '';
    display: inline-block;
    background-image: url('../images/Previous_disabled.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 19px;
    height: 17px;
    border: none;
    cursor: auto;
    background-color: transparent;
  }
  
  .pagination .change .nextButton:disabled {
    content: '';
    display: inline-block;
    background-image: url('../images/Next_disabled.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 19px;
    height: 17px;
    border: none;
    cursor: auto;
    background-color: transparent;
  }
  .pagination .change .current {
    margin: 10px;
  }
  
  .currentpage {
    border: none;
    width: 24px;
    height: 15px;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 7px;
    background-color: #ffffff;
  }
  
  .currentpage:focus-visible {
    outline: none;
   }
  .pointer {
    cursor: pointer;
  }