.question_bank_table_top {
  padding: 28px 30px;
}

.question_bank {
  width: 100%;
}

.required_field {
  color: red;
}

.table_content_report {
  width: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2%;
  height: 454px;
  box-shadow: 0px 8px 24px 0px #7090b026;
}
.table_content_report::-webkit-scrollbar {
  width: 10px; 
  height: 10px;
}
.table_content_report::-webkit-scrollbar-thumb {
  background-color:#DADCE0;
  border-radius: 4px;
}

.table_content_report::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.user_table_content {
  font-family: 'IBM Plex Sans', sans-serif;
  height: 70vh;
  margin-top: 0;
}

/* style.table_content_report */

.table_content_report .top_content_table {
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
}

.table_content_report .top_content_table .table_title {
  height: 31px;
  gap: 24px;
  display: flex;
  width: 30%;
  margin-left: 20px;
}

.table_content_report .top_content_table .table_title span {
  font-size: 16px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
}

.table_content_report .top_content_table .search_listing {
  width: 85%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #eceff1;
}

.search_listing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search_listing .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90px;
  width: 100%;
}

.search_listing .tag_icon span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
}

.search_listing .input_search {
  border-left: 1px solid rgb(235, 225, 225);
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
}

.search_listing .input_search input,
.search_listing .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
}

.user_management_search_listing {
  margin: 10px 30px;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.filter {
  width: 20%;
  height: 24px;
  display: flex;
  justify-content: end;
  margin-right: 20px;
}

.filter div {
  display: inline-flex;
  align-items: center;
}

.filter div span {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}

.user_management_filter {
  justify-content: start;
}

.add_question {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 35%;
  height: 108px;
}

.add_new_question {
  background-color: #00abfb;
  max-width: 228px;
  width: 45%;
  margin-right: 25px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_new_question span {
  font-size: 16px;
  color: #ffffff;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
}

.sub_head_content_table table tbody tr td {
  border-bottom: 2px solid #f6f7fb !important;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 3%;
  padding-right: 13px;
}

.table_content_report .sub_head_content_table table {
  border-spacing: 0px !important;
  width: 100%;
}

.table_content_report .sub_head_content_table table thead {
  background-color: #e9f8ff;
  height: 41px;
  text-align: center;
  font-size: 12px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 14px;
}

.table_content_report .sub_head_content_table table thead .very_first {
  text-align: left !important;
  padding-left: 26px;
  height: 28px;
}

.table_content_report .sub_head_content_table table thead .first {
  height: 28px;
  text-align: left;
  width: 14%;
  padding-left: 3%;
}

.table_content_report .sub_head_content_table table thead .second {
  height: 28px;
  text-align: left;
  width: 15%;
  padding-left: 3%;
}

.table_content_report .sub_head_content_table table thead .third {
  height: 28px;
  text-align: left;
  padding-left: 3%;
  width: 25%;
}

.table_content_report .sub_head_content_table table thead .fourth {
  height: 28px;
  text-align: left;
  padding-left: 3%;
  width: 25%;
}

.table_content_report .sub_head_content_table table thead .fifth {
  height: 28px;
  text-align: left;
  padding-left: 3%;
  width: 12%;
}

.table_content_report .sub_head_content_table table thead th span {
  margin-right: 4px;
}

.user_management_table_body_content {
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: left;
}
.userFirstname {
  width: 110px;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
}
.userLasttname {
  width: 110px;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
}
.userOrganization {
  width: 110px;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
}
.userRole {
  width: 110px;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
}
.userQuestionbanks {
  width: 215px;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
}
.adminOrg {
  display: block !important;
  width: 331px;
  white-space: normal;
  overflow-wrap: break-word;
  position: relative;
  top: 20px;
}
.OrgButton{
  width:63% !important;
}
.userStatus {
  width: 100px;
  white-space: normal;
  overflow-wrap: break-word;
  display: block;
}
.activate {
  background-color: #e9fcf1;
  color: #26ab5b;
  width: 62px;
  height: 20px;
  border: 0.3px solid #26ab5b;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.user_table_content .activate {
  background-color: #e9fcf1;
  color: #26ab5b;
  width: 62px;
  height: 20px;
  border: 0.3px solid #26ab5b;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.user_table_content .inactive {
  background-color: #f6f7fb;
  color: #8e9eab;
  width: 62px;
  height: 20px;
  border: 0.3px solid #8e9eab;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.inactive {
  background-color: #f6f7fb;
  color: #8e9eab;
  width: 62px;
  height: 20px;
  border: 0.3px solid #8e9eab;
  border-radius: 4px;
  font-size: 10px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 4px 8px 4px 8px;
}

.action {
  margin-right: 26px;
  position: relative;
}

.top_section {
  display: block;
}

.top_section .left {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}

.top_section .right {
  display: inline-flex;
  float: right;
  align-items: center;
  color: #00abfb;
  font-size: 16px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
  cursor: pointer;
}

.profile_detail_div .status {
  float: right;
  background-color: #e9fcf1;
  border: 1px solid #26ab5b;
  color: #26ab5b;
  font-size: 16px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  padding: 5px 20px;
}

.profile_detail_div {
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 26px;
  padding: 20px 32px 10px 30px;
}

.profile_detail_div_font {
  font-weight: 600;
}

.profile_title_div {
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 26px;
  margin-left: 30px;
}

.user_profile .tags .subject p {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.user_profile .tags .subject {
  width: 30%;
}

.sub_label {
  width: 30%;
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.subject_inp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 20px;
  padding-right: 50px;
  font-size: 12px;
  appearance: auto;
  box-sizing: border-box;
}

.user_profile .tags .subject input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 20px;
  font-size: 12px;
}

.user_profile .tags {
  display: flex;
  gap: 20px;
}

.user_profile {
  padding: 0px 30px;
}

.profile_status {
  padding: 10px 30px;
}

.profile_status .info {
  padding: 20px 0px;
}

.profile_status .title {
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.select_question .info {
  display: flex;
  flex-wrap: wrap;
  gap: 56px;
  padding-top: 3.2%;
  padding-bottom: 3.2%;
}

.select_question .info .format {
  max-height: 56px;
  max-width: 305px;
  width: 100%;
  height: 100%;
}

.select_question .info .format_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .info .format_choice {
  display: flex;
  margin-top: 16px;
  gap: 26px;
}

.select_question .info .format_choice div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_question .info .format_choice label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.select_question .info .format_choice input {
  margin: 0px;
  margin-right: 6px;
  width: 19px;
  height: 19px;
}

.red_mendantory {
  color: red;
}

.dropdown_css {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(203, 201, 201);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 0px 20px;
  font-size: 12px;
}

.roleBox {
  color: black;
  margin: 5px 10px;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  background: #f6f7fb;
  border: none;
  border-radius: 10px;
}

.roleBox svg {
  margin-top: 5px;
  margin-left: 10px;
}

.question_bank_table {
  border: 1px solid #ecf3f5;
  border-radius: 10px;
}

.thead_question_bank {
  display: flex;
  width: 100%;
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 20px;
}

.thead_question_bank div {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.thead_question_bank .first {
  border-top-left-radius: 10px;
  background-color: #f6f7fb;
  border-bottom: 1px solid #ecf3f5;
  text-align: left;
}

.thead_question_bank .last {
  border-top-right-radius: 10px;
  background-color: #f6f7fb;
  border-bottom: 1px solid #ecf3f5;
}

.thead_question_bank .second {
  background-color: #f6f7fb;
  border: 1px solid #ecf3f5;
}

.thead_question_bank .third {
  background-color: #f6f7fb;
  border: 1px solid #ecf3f5;
}

.thead_question_bank .fouth {
  background-color: #f6f7fb;
  border: 1px solid #ecf3f5;
}

.tbody_question_bank {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.tbody_question_bank div {
  text-align: center;
  text-align: left;
}

.tbody_question_bank .first {
  text-align: left;
}

.tbody_question_bank .last_name {
  text-align: left;
}

.profile_detail_info {
  padding: 5px;
}

.title_detail {
  font-size: 14px;
  font-weight: 600;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
}

.description_detail {
  font-size: 14px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
}

.profile_detail_info .second,
.profile_detail_info .third,
.profile_detail_info .fouth,
.profile_detail_info .last {
  text-align: left !important;
}

.saveButton_questionBank {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.saveButton_questionBank .cancel {
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.margin_top_20 {
  margin-top: 20px;
}

.margin_to_save {
  margin-top: 0px;
  margin-left: 30px;
  margin-bottom: 12px;
}

.headerTitle {
  font-size: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 20px;
  color: #272727;
}

.blue_button {
  background-color: #00abfb;
  color: white;
  width: 197px;
  height: 56px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 4px 6px 0px #0000000f;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
}

.pointer {
  cursor: pointer;
}

.user_detail_arrow {
  padding-left: 1% !important;
}

.title {
  width: 167px;
  height: 32px;
  top: 412px;
  left: 248px;
  gap: 0px;
  border-radius: 14px 0px 0px 0px;

}

.title_typography {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
  text-align: left !important;
  margin-bottom: 12px !important;
  width: auto;
  height: auto;
}

.role_permission {
  font-family:'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #272727;
}

.table_container {
  box-shadow: 0px 4px 6px 0px #0000000f;
  width: 97%;
  overflow-x: auto;
  margin: 20px 0;
  border-radius: 10px;
}

.responsive_table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 16px;
  border-radius: 10px;
  overflow: hidden;
}

.responsive_table thead {
  background-color: #f6f7fb;
  font-family:'IBM Plex Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.responsive_table th,
.responsive_table td {
  text-align: center;
  padding: 10px;
  border: 1px solid #ecf3f5;
  white-space: nowrap;
  height: 50px;
  vertical-align: middle;
  color: #272727;
}

.responsive_table td:first-child {
  padding-left: 17px;
  font-family:'IBM Plex Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.responsive_table th {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Allow wrapping for the first column */
.responsive_table th:first-child,
.responsive_table td:first-child {
  white-space: normal;
  word-wrap: break-word;
  max-width: 200px;
  text-align: left;
  padding-left: 31px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .responsive_table {
    font-size: 14px;
  }

  .responsive_table th,
  .responsive_table td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .responsive_table th,
  .responsive_table td {
    font-size: 12px;
    padding: 6px;
  }

  .responsive_table thead {
    display: none;
  }

  .responsive_table tr {
    display: block;
    margin-bottom: 15px;
  }

  .responsive_table td {
    display: block;
    text-align: right;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    height: auto;
  }

  .responsive_table td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    text-align: left;
    font-weight: bold;
  }
}

.question_bank_names {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
  flex-wrap: wrap;
}

.question_bank_name {
  margin-bottom: 5px;
  padding: 8px 10px;
  border: 1px solid #ecf3f5;
  border-radius: 14px;
  background-color: #f6f7fb;
  font-family:'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/*Pagination css*/
.pagination_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  background-color: #f6f7fb;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.pagination_info {
  font-family:'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8e9eab;
}

.pagination_input {
  width: 40px;
  text-align: center;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  padding: 3px 0px;
  color: #272727;
}

.pagination_controls {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.pagination_button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
}

.pagination_button:disabled {
  background-color: transparent;
  cursor: not-allowed;
}

.pagination_button:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.pagination_icon {
  width: 6px;
  height: 10px;
}

.pagination_icon.active {
  filter: invert(44%) sepia(94%) saturate(2373%) hue-rotate(188deg) brightness(102%) contrast(101%);
}

.pagination_icon.disabled {
  filter: invert(73%) sepia(7%) saturate(31%) hue-rotate(180deg) brightness(95%) contrast(89%);
}

/* Style for the vertical line */
.vertical_line {
  width: 1px;
  height: 80px;
  margin-left: 30px;
  margin-right: 15px;
  background-color: #c7ccd1;
  display: inline-block;
}

/* Optionally, for better alignment with text */
.tbody_question_bank > div {
  display: inline-block;
  vertical-align: top;
}

.pagination_controls span {
  color: #272727;
}

/* Custom radio buttons */
/* Hide the default radio button */
.custom_radio input[type='radio'] {
  display: none;
}

/* Create the custom radio button */
.custom_radio .radio_checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid #00abfb;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease;
}

/* Change the appearance when the radio button is checked */
.custom_radio input[type='radio']:checked + .radio_checkmark {
  border-color: #00abfb;
}

/* Add a circle inside the radio button when checked */
.custom_radio input[type='radio']:checked + .radio_checkmark::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00abfb;
  position: absolute;
  top: 4px;
  left: 4px;
}

/* Add hover effect */
.custom_radio .radio_checkmark:hover {
  border-color: #00abfb;
}

.second .description_detail {
  text-decoration: underline;
}

/* Target the first column of the first row */
.responsive_table thead tr:first-child th:first-child,
.responsive_table thead tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

/* Target the last column of the first row */
.responsive_table thead tr:first-child th:last-child,
.responsive_table thead tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

/* Bottom left corner for the last row's first column */
.responsive_table tbody tr:last-child th:first-child,
.responsive_table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

/* Bottom right corner for the last row's last column */
.responsive_table tbody tr:last-child th:last-child,
.responsive_table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

/**** PAGINATION**/

.user_paginationWrap {
  width: 82.5vw;
  height: 13vh;
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.user_paginationWrap .pagination {
  display: flex;
  margin-left: 20px;
  bottom: 34px;
  position: absolute;
  justify-content: flex-end;
  padding: 6px;
  width: 90%;
  background: #f6f7fb;
  box-shadow: 0px 0px 41px 0px #00000008;
  border-radius: 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
}

.user_paginationWrap .pagination .number {
  display: flex;
  color: #8e9eab;
  margin-right: 10px;
  align-items: center;
}

.user_paginationWrap .pagination .change {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.user_paginationWrap .pagination .line {
  display: flex;
  align-items: center;
}

.user_paginationWrap .pagination .change button {
  border: none;
  border-radius: 10px;
  align-items: center;
  display: flex;
  padding: 8px;
  background: white;
}

.user_paginationWrap button:disabled {
  cursor: auto;
  background-color: rgb(240, 240, 240);
}

.user_paginationWrap .pagination .change .current {
  margin: 10px;
}

.user_paginationWrap .currentpage {
  border: none;
  width: 36px;
  height: 15px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 7px;
  background-color: #ffffff;
}

.selectedquestion {
  font-weight: 500 !important;
}

.user_paginationWrap .back_button {
  border: none;
  padding: 15px 15px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.user_table_content .add_question {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 55%;
  height: 105px;
}

.user_table_content .add_new_question {
  width: 50%;
  margin-top: 9px;
}

.user_table_content .noDataText {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  padding: 30px;
}

.user_table_content .search_listing .input_search {
  border-left: none;
}

.que_bank_count {
  position: relative;
  background: #ecf3f5;
  border-radius: 50%;
  padding: 6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  color: #272727;
  font-weight: 500;
}

.moreQuebank {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  white-space: normal;
  width: 16vw;
  top: 83%;
  right: 0;
}

.user_table_content .search_listing .input_search input {
  padding-left: 0;
}

.user_table_content .tag_icon {
  width: auto;
  padding: 0 14px;
}

.user_table_content .sub_head_content_table {
  padding-top: 10px;
}

.user_table_content .search_listing {
  height: 50px !important;
  margin: 18px 30px 10px !important;
}

.list_input_primary {
  position: absolute;
  top: 55px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  width: 95%;
  padding: 10px;
  border-radius: 10px;
  z-index: 50;
  height: 120px;
  overflow-y: auto;
}

.list_input_primary div {
  padding: 8px 5px;
}

.list_input_primary div:hover {
  background-color: #c2e9fb;
}

.selected_tags_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 30px;
}

.chips {
  align-items: center;
  background-color: #f6f7fb;
  border: none;
  border-radius: 14px;
  display: inline-flex;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 100%;
  overflow-wrap: break-word;
  padding: 15px 15px;
  white-space: normal;
  word-break: break-all;
  gap: 12px;
}

.table_height {
  height: 76vh;
}

.table_height::-webkit-scrollbar-thumb {
  background-color: #dadce0 !important;
  border-radius: 4px;
}

.table_height::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
}

.table_height .profile_detail_div {
  display: flex;
  justify-content: space-between;
}

.first_column {
  font-weight: 400 !important;
  color: #272727 !important;
}

.second_all_column {
  font-weight: 500 !important;
  color: #272727 !important;
}

.no_data_message {
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  height: 100px !important;
}

.all_column {
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
}

.new_shadow {
  padding: 34px 25px 15px 25px;
}

.table_content_typography {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.pagination_user_detail {
  padding: 10px 63px 10px 30px;
}

.table_content_adduser {
  height: 77vh;
}

.table_content_adduser .dropdown_css {
  color: #8e9eab;
}

option:not(:disabled) {
  color: #000;
}

.table_content_adduser .dropdown_css.selected {
  color: #272727;
}
input::placeholder {
  color: #8e9eab;
}
.table_content_adduser .profile_title_div {
  width: 36%;
}

.paginationWrap {
  width: 82.5vw;
  height: 13vh;
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.pagination {
  display: flex;
  margin-left: 20px;
  bottom: 34px;
  position: absolute;
  justify-content: flex-end;
  padding: 6px;
  width: 90%;
  background: #f6f7fb;
  box-shadow: 0px 0px 41px 0px #00000008;
  border-radius: 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'IBM Plex Sans', sans-serif;
}

.pagination .number {
  display: flex;
  color: #8e9eab;
  margin-right: 10px;
  align-items: center;
}

.pagination .change {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.pagination .line {
  display: flex;
  align-items: center;
}

.pagination .change button {
  border: none;
  border-radius: 10px;
  align-items: center;
  display: flex;
  padding: 8px;
  background: white;
}
button:disabled {
  cursor: auto;
  background-color: rgb(240, 240, 240);
}
.pagination .change .current {
  margin: 10px;
}

.currentpage {
  border: none;
  width: 36px;
  height: 15px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 7px;
  background-color: #ffffff;
}
.firstName {
  width: 100px;
  white-space: normal;
  overflow-wrap: break-word;
}
.lastName {
  width: 90px;
  white-space: normal;
  overflow-wrap: break-word;
}
.userEmail {
  width: 130px;
  white-space: normal;
  overflow-wrap: break-word;
}
.mobileNumber {
  width: 100px;
  white-space: normal;
  overflow-wrap: break-word;
}
.organizationName {
  width: 100px;
  white-space: normal;
  overflow-wrap: break-word;
}
.roleName {
  width: 100px;
  white-space: normal;
  overflow-wrap: break-word;
}
/******Organization lit css******/
.orgFilter {
  position: relative;
  left: 81px;
  top: 5px;
}

/* Start of CSS for Organization Admin Column width only */
.sub_head_content_table_new .orgadmin_col_width {
  width: 35% !important;
}
/* End of CSS for Organization Admin Column width only */