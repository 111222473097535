.filterModal {
  font-family: 'IBM Plex Sans', sans-serif;
  position: fixed;
  background: #000000ba;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 60;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}
.addPipelinefilterModel {
  height: 85vh;
  overflow-y: scroll;
}
.addPipelinefilterModel::-webkit-scrollbar {
  width: 10px; /* Set the width of the vertical scrollbar */
  height: 10px; /* Set the height of the horizontal scrollbar */
}
.addPipelinefilterModel::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.addPipelinefilterModel::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.filterModal .center_div {
  width: 55%;
  background-color: white;
  height: 85%;
  border-radius: 30px;
}

/* ------------ */
.filterModal .center_div_larger .top_title {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  text-align: center;
}

.title {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-top: 2%;
}

.back_button {
  margin-left: 2%;
  margin-top: 1%;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: underline;
}

.back-button:hover {
  text-decoration: underline;
}

.arrow {
  font-size: 14px;
  margin-right: 8px;
  margin-top: 3px;
}

.filterModal .center_div_review {
  width: 70%;
  background-color: white;
  height: 85%;
  border-radius: 30px;
}

.filterModal .center_div .header_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(241, 236, 236);
  -webkit-box-shadow: 0 8px 6px -6px rgb(230, 216, 216);
  -moz-box-shadow: 0 8px 6px -6px rgb(223, 203, 203);
  box-shadow: 0 8px 6px -6px rgb(220, 211, 211);
}

.filterModal .center_div .header_top .title {
  padding: 2.2% 3%;
  width: 50%;
}

.filterModal .center_div .header_top .title div {
  display: flex;
  align-items: center;
}

.filterModal .center_div .header_top .title div span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.filterModal .center_div .header_top .close {
  width: 50%;
  padding-right: 0.5%;
}

.filterModal .center_div .header_top .close button {
  float: right;
  margin-right: 5%;
  background-color: white;
  border: none;
}

.input_search span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding-left: 20px;
  align-items: center;
  display: inline-flex;
}

.list_input_primary {
  position: absolute;
  top: 55px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  z-index: 50;
}

.list_input_primary button {
  padding: 8px 5px;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  background: none;
  border: none;
}

.list_input_primary button:hover span {
  background-color: #00abfb;
  color: white;
}

.list_input_primary button span {
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

.filterModal .center_div .top_title {
  width: 100%;
}

.filterModal .center_div .top_title .title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filterModal .center_div .top_title .title div {
  display: flex;
  align-items: center;
}

.filterModal .center_div .top_title .title div span {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.master_modal .tags .subject p {
  font-size: 14px;
  color: #272727;
  margin-bottom: 8px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18px;
}

.master_modal .tags .subject {
  width: 100%;
}

.master_modal .tags .subject input {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 20px;
  font-size: 14px;
}

.master_modal .tags {
  display: flex;
  gap: 20px;
}

.master_modal {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.saveButton_questionBank {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.saveButton_questionBank .cancel {
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'IBM Plex Sans', sans-serif;
  background: none;
  border: none;
  padding: 0px;
}

.margin_top_20 {
  margin-top: 20px;
}

.blue_button {
  background-color: #00abfb;
  color: white;
  width: 190px;
  height: 50px;
  border: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  cursor: pointer;
}
.blue_button:disabled {
  background-color: #c7ccd0;
}

.filterModal .center_div_large {
  width: 65%;
  background-color: white;
  height: 85%;
  border-radius: 30px;
  overflow-y: scroll;
}
.center_div_large {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgb(231, 226, 226);
  padding: 15px;
  padding-left: 18px;
}

.filterModal .center_div_large .top_title {
  width: 100%;
}

.filterModal .center_div_large .top_title .title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.filterModal .center_div_large .top_title .title div {
  display: flex;
  align-items: center;
}

.filterModal .center_div_large .top_title .title div span {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.filterModal .center_div_large .top_title .close {
  width: 100%;
}

.filterModal .center_div_large .top_title .close button {
  float: right;
  margin-right: 2%;
  margin-top: 2%;
  background-color: white;
  border: none;
}

.identifier_master_modal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.identifier_master_modal .master_modal {
  width: 100%;
}

.identifier_save {
  float: right;
  padding: 5%;
}

.master_tag_search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid #f0f6f7;
  max-width: 574px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.master_tag_search .tag_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px;
  width: 100%;
}

.master_tag_search .tag_icon span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
}

.master_tag_search .input_search {
  height: 100%;
  display: flex;
  margin: 10px;
  width: 100%;
  position: relative;
}

/* adding editSubjectpipeline class here */
.input_search input {
  padding: 8px;
  color: #333;
}

.master_tag_search .cancel_button {
  float: right;
}

.master_tag_search .cancel_button button {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.master_tag_search:focus-within {
  background-color: #fff !important;
}

.slugs_Tag {
  border-radius: 50px;
  background-color: #e8f8ff;
  border: none;
  font-size: 14px;
  line-height: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  padding: 10px 25px;
  display: inline-flex;
  align-items: center;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-all;
  max-width: 100%;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

/**** DELETE MODAL***/

.delete {
  height: 40% !important;
}

.center {
  justify-content: center !important;
  width: 100%;
}

/*** CATEGORY ADD MODAL **/

.AddpipelineWaterMark {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #8e9eab;
  text-align: left;
  display: block;
  margin-top: 20px;
  max-width: 100%;
}

/* _____________________________ Test filter __________*/

.templateGroup span {
  width: 100%;
  text-align: left;
}

select option:hover {
  background-color: #00abfb;
  color: white;
  cursor: pointer;
}

.list_input_primary li {
  color: black;
  border-radius: 50px;
  background-color: #f4f5f6;
  padding: 5px 22px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  width: fit-content;
}

.list_input_primary li:hover {
  background: #00abfb;
  color: #ffffff;
  cursor: pointer;
}

.input_search textarea {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  width: 100%;
  border: none;
}

/* adding editsubpipline css */

.input_search input::placeholder {
  color: #aaa;
}

.master_tag_search .input_search input,
.master_tag_search .input_search input:focus-visible {
  border: none;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  background-color: #ffffff;
}

.input_search input:focus {
  outline: none;
  border-color: #007bff;
}

.input_search textarea:focus {
  outline: none;
}

.selected_tags_list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.selected_tag_item {
  background-color: #f5f6fa;
  padding: 12px 12px;
  border-radius: 20px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.remove_icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  fill: #272727;
  transition: transform 0.2s;
}

.remove_icon:hover {
  transform: scale(1.2);
}

/*responsiveness in tablet mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mediumModal {
    position: absolute;
    height: 100vh;
    background: #000000ba;
    width: 100vw;
    top: 0px;
    left: -111px;
  }
}

/*****media querry for laptop mode ****/
@media screen and (min-width: 1024px) and (max-width: 1439px) and (min-height: 600px) {
  .mediumModal {
    position: absolute;
    height: 100vh;
    background: #000000ba;
    width: 100vw;
    top: 0px;
    left: -171px;
  }
}

/**** destop *****/
@media screen and (min-width: 1440px) and (min-height: 689px) {
  .mediumModal {
    position: absolute;
    height: 100vh;
    background: #000000ba;
    width: 100vw;
    top: 0px;
    left: -240px;
  }
}

.validationError {
  color: red;
  font-size: 12px;
  margin-top: -15px;
}
.master_model_inside {
  margin-left: 45px;
  margin-right: 45px;
}

.desctextarea {
  position: absolute;
  width: 70%;
  height: 80%;
  background-color: transparent;
  top: 10px;
}
/* Title Tags */
.add_title {
  margin: 0;
  font-size: 20px;
}
.addtitle_tag {
  margin: 0;
  margin-top: 28px;
  font-weight: 700;
}
.required_field {
  color: red;
}
.subtopicDescripTextarea {
  font-family: 'IBM Plex Sans', sans-serif;
  resize: none;
}
/* Only show scrollbar when content overflows */
.subtopicDescripTextarea:focus {
  overflow-y: auto;
}

.subtopicDescripTextarea::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.subtopicDescripTextarea::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 4px;
}

.subtopicDescripTextarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
