div#right_panel {
  width: 87.5vw;
  height: 100vh;
  transition: all 0.5s;
  position: fixed;
  top: 0;
  right: 0;
  outline: 1px solid #ecf3f5;
}

div#right_panel.show {
  width: 100%;
  padding-left: 0px;
}

div#right_panel .search_bar {
  background: #ffffff;
}

div#right_panel .search_bar.show {
  left: 0px;
  width: 100%;
  height: 10vh;
  align-items: center;
  display: inline-flex;
}

div#right_panel .search_bar.show .profile {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 40px;
  gap: 20px;
}

div#right_panel .search_bar.show .profile .image {
  margin-left: 16px;
}
div#right_panel .search_bar.show .profile .image img {
  width: 40px;
  height: 40px;
}

div#right_panel .search_bar.show .profile .name h4 {
  height: 18px;
  margin: 0px;
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  line-height: 18.2px;
  text-align: right;
}

div#right_panel .search_bar.show .profile .name p {
  font-size: 14px;
  margin: 0px;
  text-decoration: underline;
  color: #00abfb;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 18.2px;
  text-align: right;
  cursor: pointer;
}

/*organization*/
div#right_panel .search_bar.show .profile .organizationDrop .organizationSelect {
  width: 290px;
  height: 48px;
  background-color: transparent;
  appearance: none;
  border: 1.5px solid #ECF3F5;
  border-radius: 14px;
  padding-left: 10px;
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 18px;
}
/**** RIGHT PANEL HEADER FILE CSS**/

div#right_panel .search_bar.show .header {
  position: relative;
  left: 30px;
  font-weight: 600;
}

div#right_panel .search_bar.show .header_content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

div#right_panel .search_bar.show .header .header_content .header_text {
  font-size: 18px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  line-height: 26px;
}

div#right_panel .search_bar.show .header .header_content .header_drop {
  margin-left: 40px;
}

div#right_panel .search_bar.show .header .header_content .header_drop select {
  width: 290px;
  height: 48px;
  background-color: transparent;
  appearance: none;
  border: 1.5px solid #ECF3F5;
  border-radius: 14px;
  padding-left: 10px;
  font-size: 14px;
  color: #272727;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 18px;
}
.dropdownSelectArrow{
  position: relative;
  right: 30px;
  cursor: pointer;
}
/*responsiveness in tablet mode */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  div#right_panel {
    width: 85vw;
  }
}

@media (min-width: 1024px) and (max-width: 1920px) {
  /* Styles for laptops */
  div#right_panel {
    width: 87vw;
  }
}

